// tools
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import axios from "axios";
import User from "../../tools/userInfo";
import Swal from "sweetalert2";
import { update } from "../../tools/zustandUpdate";
import { useGoogleLogin } from "@react-oauth/google";

//
import { TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

// img
import Apple from "../../img/common/apple-ico.svg";
import Google from "../../img/common/google-ico.svg";
import Facebook from "../../img/common/facebook-ico.svg";

// social
// import FacebookLoginButton from "./socialLogins/facebookLogin";
// import TiktokLoginButton from "./SocialLogins/tiktokLogin";
// import AppleLoginButton from "./SocialLogins/appleLogin";

// comp
import { CustModal } from "./commonElements";

interface LoginProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

interface InfoValue {
  id: string;
  pw: string;
}

const Login = ({ open, setOpen }: LoginProps) => {
  const { api, setToken, setTotalInfo } = User();
  const [infoValue, setInfoValue] = useState<InfoValue>({
    id: localStorage.getItem("id") || "",
    pw: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [remember, setRemember] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInfoValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const googleLogin = useGoogleLogin({
    scope: "email profile",
    flow: "auth-code",
    ux_mode: "redirect",
    redirect_uri: `${location.origin}/auth/google`,
    state: location.href,
  });

  const loginAxios = () => {
    if (remember) {
      localStorage.setItem("id", infoValue.id);
    }

    axios
      .post(
        `${api}/login`,
        { email: infoValue.id, password: infoValue.pw },
        { headers: { "ngrok-skip-browser-warning": "69420" } }
      )
      .then(({ data }) => {
        if (data.message === "success") {
          setToken(data.data.token);
          setOpen(false);
          Swal.fire({
            title: "Login Success",
            icon: "success",
            allowOutsideClick: false,
            timer: 2000,
            didClose: () => {
              update(api, data.data.token, setTotalInfo);
              if (location.pathname === "/welcome") location.replace("/");
              if (location.pathname === "/cart") location.replace("/");
            },
          });
        }
      })
      .catch(({ response }) => {
        setOpen(false);
        Swal.fire({
          title: response.data.message,
          icon: "error",
          allowOutsideClick: false,
          timer: 2000,
        });
      });
  };

  useEffect(() => {
    const savedId = localStorage.getItem("id");
    if (savedId) {
      setInfoValue((prev) => ({ ...prev, id: savedId }));
      setRemember(true);
    }
  }, []);

  useEffect(() => {
    if (remember) {
      localStorage.setItem("id", infoValue.id);
    } else {
      localStorage.removeItem("id");
    }
  }, [remember, infoValue.id]);

  return (
    <CustModal open={open} setOpen={setOpen} onClose={handleClose}>
      <div className="login">
        <h3 className="tit">Log in</h3>
        <span
          className="btn close material-symbols-rounded"
          onClick={handleClose}
        >
          close
        </span>

        <div className="login-wrap">
          <FormControl sx={{ width: 1 }}>
            <TextField
              onChange={handleChange}
              id="idInput"
              name="id"
              value={infoValue.id}
              label="Email"
              type="Email"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  loginAxios();
                }
              }}
            />
          </FormControl>
          <FormControl sx={{ width: 1, mt: 2 }}>
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              onChange={handleChange}
              id="outlined-adornment-password"
              name="pw"
              value={infoValue.pw}
              type={showPassword ? "text" : "password"}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  loginAxios();
                }
              }}
              endAdornment={
                <InputAdornment position="start">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <span className="material-symbols-rounded">
                        visibility
                      </span>
                    ) : (
                      <span className="material-symbols-rounded">
                        visibility_off
                      </span>
                    )}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>

          <FormControlLabel
            control={
              <Checkbox
                checked={remember}
                onChange={({ target }) => setRemember(target.checked)}
              />
            }
            label="Remember Me"
          />

          <div
            className="btn style01 lg login spc"
            onClick={() => loginAxios()}
          >
            <span>Log in</span>
          </div>

          <div className="signup-area">
            <p>Don't have an account?</p>
            <div
              className="btn"
              onClick={() => {
                setOpen(false);
                location.href = "/signup";
              }}
            >
              Signup!
            </div>
          </div>
          <p className="or">or</p>
          <div className="sns-area">
            <img
              src={Google}
              alt="Google login"
              className="btn"
              onClick={() => googleLogin()}
            />
            <img src={Facebook} alt="Faceboook login" />
            <img src={Apple} alt="Apple login" />

            {/* 각 개발자 도구에서 APP KEY 등 설정 필요 */}
            {/* <FacebookLoginButton /> */}
            {/* <AppleLoginButton /> */}
            {/* <TiktokLoginButton /> */}
          </div>
        </div>
      </div>
    </CustModal>
  );
};

export default Login;
