// tools
import { useState } from "react";
import { SyndicateInfo } from "../../pages/syndicates";
import Countdown, { CountdownRenderProps } from "react-countdown";

// img
import Mega from "../../img/common/mega-ball-logo.png";
import Power from "../../img/common/power-ball-logo.png";

// layout
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import axios from "axios";
import User from "../../tools/userInfo";
import Swal from "sweetalert2";
import { update } from "../../tools/zustandUpdate";
import { useLotto } from "../../App";

// type
interface SynItemProps {
  data: SyndicateInfo;
  getSynData: () => void;
}

const SynItem = ({ data, getSynData }: SynItemProps) => {
  // provider
  const { limitCart } = useLotto();

  // stat
  const [numOpen, setNumOpen] = useState<boolean>(false);
  const [selectTicket, setSelectTicket] = useState<number>(1);
  const { info, api, token, setTotalInfo } = User();

  // cons
  const parseDate = new Date(data.auDrawTime);
  const remain = data.ticketIssued - data.ticketSold;

  // func
  const amountMaker = (amount: number) => {
    const inmil = Number((amount / 1000000).toFixed(1));
    let final = 0;

    if (inmil > 1000) {
      const inbil = inmil / 1000;
      final = Math.floor(inbil * 100) / 100;
    } else if (inmil > 0) {
      final = inmil;
    }
    return final;
  };

  const isGameType = (type: string) => {
    if (type === "PB") {
      return "power";
    } else {
      return "mega";
    }
  };

  // api
  const addSynCart = (synItem: SyndicateInfo, qty: number) => {
    const data = {
      idx: synItem.idx,
      ticketQty: qty,
      amount: selectTicket * Number(synItem.price),
    };

    if (info.cart >= limitCart) {
      Swal.fire({
        icon: "warning",
        title: "You cannot purchase more than 20 games in the cart.",
      });
    } else {
      axios
        .post(`${api}/cart/syndicate`, data, {
          headers: {
            "ngrok-skip-browser-warning": "69420",
            Authorization: "bearer " + token,
          },
        })
        .then((res) =>
          Swal.fire({
            icon: "success",
            title: "Completed Add to Cart!",
            allowOutsideClick: false,
            didClose: () => {
              getSynData();

              axios
                .get(`${api}/auth/me`, {
                  headers: {
                    "ngrok-skip-browser-warning": "69420",
                    Authorization: "bearer " + token,
                  },
                })
                .then((res) => update(api, token, setTotalInfo));
            },
          })
        )
        .catch((error) => {
          console.error(error);
          Swal.fire({
            icon: "warning",
            title: "ticket remain is changed, please retry",
            didClose: () => window.location.reload(),
          });
        });
    }
  };

  console.log(data);

  return (
    <>
      <div className="item">
        <div className="game-info-area">
          <img
            src={isGameType(data.productCode) === "power" ? Power : Mega}
            alt="Mega Millions"
          />
          <div className="item-section">
            <div className="name-tag-wrap">
              <p
                className={`name ${
                  isGameType(data.productCode) === "power" ? "power" : "mega"
                }`}
              >
                {isGameType(data.productCode) === "power"
                  ? "Power ball"
                  : "Mega Millions"}
              </p>

              {/* --- tag --- */}
              {data.multiplier === "N" && (
                <p className="tag multiplier">Multiplier</p>
              )}
              {data.systemCode === "GUA" && (
                <p className="tag guaranteed">Guaranteed</p>
              )}
              {data.systemCode?.includes("ST") && (
                <p className="tag system">System {data.systemCode}</p>
              )}
            </div>
            <p className="prize">
              <span className="unit">$ </span>
              <span className="num">{amountMaker(data.jackpot)}</span>
              <span className="unit"> Million</span>
            </p>

            <p className="time">
              <Countdown
                date={parseDate}
                renderer={(props) => <CountDownView {...props} />}
                intervalDelay={60000}
              />
            </p>
          </div>
          <div className="item-section">
            <p className="remain">{remain} shares remaining</p>
            <p className="tshare">Total shares : {data.ticketIssued}</p>
            <FormControl fullWidth>
              <InputLabel id="Syndigate">Syndigate</InputLabel>
              <Select
                labelId="Syndigate"
                id="Syndigate"
                label="Syndigates"
                defaultValue={1}
              >
                {Array.from({ length: remain }, (_, index) => {
                  const value = index + 1;
                  const price = value * Number(data.price);
                  return (
                    <MenuItem
                      key={value}
                      value={value}
                      onClick={() => setSelectTicket(value)}
                    >
                      {value} Share - $ {price.toFixed(2)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <div
              className="btn style02 see-num"
              onClick={() => {
                setNumOpen(!numOpen);
              }}
            >
              {data.games} Games {!numOpen ? "( See numbers" : "( Hide numbers"}
              <span className="material-symbols-rounded">
                keyboard_arrow_down
              </span>
              )
            </div>
          </div>
          <div className="item-section">
            <p className="total">
              Total :
              <span>$ {(selectTicket * Number(data.price)).toFixed(2)}</span>
            </p>
            <div
              className="btn style01 md spc"
              onClick={() => addSynCart(data, selectTicket)}
            >
              Add to cart
            </div>
          </div>
        </div>

        {/* --- show nums --- */}
        {numOpen && (
          <div className="show-number">
            <div className="number-wrap">
              {data.nums.map((it, idx) => (
                <div className="item" key={idx}>
                  <p className="">#{idx + 1}</p>
                  <div className="ball-wrap">
                    {it.num.split(",").map((number, idx) => (
                      <div
                        key={idx}
                        className={`ball ${
                          idx === it.num.split(",").length - 1
                            ? isGameType(data.productCode)
                            : ""
                        }`}
                      >
                        {number}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SynItem;

const CountDownView = ({ days, hours, minutes }: CountdownRenderProps) => {
  const renderTime = () => {
    if (days > 0) {
      return `Close in : ${days} days, ${hours} hrs`;
    } else if (hours > 0 || minutes > 0) {
      return `Close in : ${hours} hrs, ${minutes} mins`;
    } else {
      return `Close soon`;
    }
  };

  return <p className="countdown">{renderTime()}</p>;
};
