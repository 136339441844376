// tools
import React, { useEffect, useState } from "react";
import User from "../../tools/userInfo";
import Swal from "sweetalert2";
import { reset } from "../../tools/zustandUpdate";
import axios from "axios";

// comp
import Login from "./login";

// img
import NewLogo from "../../img/common/LC_logo_ani_h.svg";

import { useLotto } from "../../App";

const Header = ({ isScrolled }: any) => {
  const { api, token, info, setToken, setTotalInfo } = User();

  const { open, setOpen } = useLotto();
  // const [open, setOpen] = useState(false);

  // refresh token
  useEffect(() => {
    if (token) {
      const payload = JSON.parse(atob(token.split(".")[1]));
      const origin = payload.iss;

      if (origin.includes("/login") || origin.includes("/refresh")) {
        const expire = payload.exp;
        const diff = new Date().getTimezoneOffset() + 540;
        const time = new Date().getTime();
        const now = (time - (time % 1000)) / 1000;
        const converted = now + diff * 60;

        if (converted > expire) {
          axios
            .get(`${api}/auth/me`, {
              headers: {
                "ngrok-skip-browser-warning": "69420",
                Authorization: "bearer " + token,
              },
            })
            .catch(({ response }) => {
              axios
                .get(`${api}/auth/refresh`, {
                  headers: {
                    "ngrok-skip-browser-warning": "69420",
                    Authorization: "bearer " + response.data.data.refresh_token,
                  },
                })
                .then(({ data }) => {
                  setToken(data.data.token);
                })
                .catch((error) => console.log(error));
            });
        }
      }
    }
  }, []);

  // logOut
  const logoutAxios = () => {
    axios
      .post(
        `${api}/logout`,
        {},
        {
          headers: {
            "ngrok-skip-browser-warning": "69420",
            Authorization: "bearer " + token,
          },
        }
      )
      .then(({ data }) => {
        if (data.message === "success") {
          Swal.fire({
            title: "You have logged out successfully",
            icon: "success",
            allowOutsideClick: false,
            timer: 2000,
            didClose: () => {
              setToken("");
              reset(setTotalInfo);
              location.href = "/";
            },
          });
        }
      });
  };

  return (
    <header className={isScrolled ? "header scroll" : "header"}>
      <div className="inner">
        <div className="logo btn" onClick={() => (window.location.href = "/")}>
          <img src={NewLogo} alt="Lottery Cluster" style={{ height: "34px" }} />
        </div>

        <ul className="gnb">
          <li onClick={() => (window.location.href = "/mega")}>
            Mega millions
          </li>
          <li onClick={() => (window.location.href = "/power")}>Powerball</li>
          <li onClick={() => (window.location.href = "/syndicates")}>
            Syndicates
          </li>
          <li onClick={() => (window.location.href = "/result")}>Results</li>
          <li onClick={() => (window.location.href = "/stat")}>Statistics</li>
          <li onClick={() => (window.location.href = "/faq")}>Faq</li>
        </ul>

        <div className="user-nav">
          {token === "" ? (
            <div className="btn style01 sm" onClick={() => setOpen(true)}>
              Login
            </div>
          ) : (
            <>
              <div
                className="btn mypage"
                onClick={() => (window.location.href = "/mypage")}
              >
                Mypage
              </div>
              <div className="btn style01 sm" onClick={() => logoutAxios()}>
                <span className="material-symbols-rounded">logout</span>
                Logout
              </div>
            </>
          )}
        </div>

        <div
          className="btn cart"
          onClick={() => (window.location.href = "/cart")}
        >
          <span className="material-symbols-rounded">shopping_cart</span>
          <span className="badge">{info.cart > 100 ? "99" : info.cart}</span>
        </div>
      </div>

      <Login open={open} setOpen={setOpen} />
    </header>
  );
};

export default Header;
