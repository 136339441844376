// tools
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import User from "../../tools/userInfo";

// ui
import Slider from "react-slick";
import Skeleton from "@mui/material/Skeleton";

// components
import NoData from "../common/noData";

//  img
import PowerBallLogo from "../../img/common/power-ball-logo.png";
import MegaBallLogo from "../../img/common/mega-ball-logo.png";

interface List {
  auDrawTime: string;
  auOrderTime: string;
  created_at: string;
  drawnum: number;
  game1: string | null;
  game2: string | null;
  game3: string | null;
  game4: string | null;
  game5: string | null;
  game_cnt: number;
  game_type: string;
  idx: number;
  prize_usd: number;
  scanPath: null | string;
  scan_status: string;
  status: string;
  winCount: null | number | string;
  win_rank: null;
  win_status: string;
  winningBalls: string;
}

const Draws = () => {
  const { api, token, info } = User();
  const ref = useRef<Slider | null>(null);
  const [toggleDateLists, setToggleDateLists] = useState(false);

  const [period, setPeriod] = useState<string[]>([]);
  const [selected, setSelected] = useState("");
  const [list, setList] = useState<List[]>([]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: period.length - 1,
    draggable: false,
    pauseOnHover: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    className: "box-slider",
  };

  function PrevArrow(props: any) {
    const clickFunc = () => {
      const { currentSlide } = props;

      if (currentSlide !== 0) {
        const copy = [...period];
        ref.current?.slickPrev();
        const current = copy.reverse()[currentSlide - 1];
        setSelected(current);
      }

      setToggleDateLists(false);
    };

    return (
      <div className="btn arr prev" onClick={() => clickFunc()}>
        <span className="material-symbols-rounded">chevron_left</span>
      </div>
    );
  }

  function NextArrow(props: any) {
    const clickFunc = () => {
      const { currentSlide, slideCount } = props;

      if (currentSlide + 1 !== slideCount) {
        const copy = [...period];
        ref.current?.slickNext();
        const current = copy.reverse()[currentSlide + 1];
        setSelected(current);
      }

      setToggleDateLists(false);
    };
    return (
      <div className="btn arr next" onClick={() => clickFunc()}>
        <span className="material-symbols-rounded">chevron_right</span>
      </div>
    );
  }

  useEffect(() => {
    const created = info.createdAt.split(" ")[0];
    const [createdYear, createdMonth] = created.split("-");
    const createdValue = Number(createdYear) * 12 + Number(createdMonth);

    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth() + 1;
    const currentValue = currentYear * 12 + currentMonth;

    for (let i = currentValue; i > createdValue - 1; i--) {
      const y = Math.floor(i / 12);
      const m = i - y * 12;

      const period = m === 0 ? `${y - 1}-12` : `${y}-${m < 10 ? `0${m}` : m}`;

      setPeriod((prev) => [...prev, period]);

      if (i === currentValue) {
        setSelected(period);
      }
    }
  }, []);

  const specificAxios = () => {
    axios
      .post(
        `${api}/order/history`,
        {
          type: "",
          period: selected,
        },
        {
          headers: {
            "ngrok-skip-browser-warning": "69420",
            Authorization: "bearer " + token,
          },
        }
      )
      .then(({ data }) => {
        setList(data.data);
      });
  };

  useEffect(() => {
    if (selected) specificAxios();
  }, [selected]);

  const MonthConverter = (month: string) => {
    const num = Number(month);

    switch (num) {
      case 1:
        return "Jan";
        break;

      case 2:
        return "Feb";
        break;

      case 3:
        return "Mar";
        break;

      case 4:
        return "Apr";
        break;

      case 5:
        return "May";
        break;

      case 6:
        return "Jun";
        break;

      case 7:
        return "Jul";
        break;

      case 8:
        return "Aug";
        break;

      case 9:
        return "Sep";
        break;

      case 10:
        return "Oct";
        break;

      case 11:
        return "Nov";
        break;

      case 12:
        return "Dec";
        break;
    }
  };

  return (
    <div className="draw">
      <div className="box-slider-wrap">
        {period.length > 0 && (
          <div className="box-tit-wrap">
            <Slider ref={ref} {...settings}>
              {[...period].reverse().map((item) => (
                <div className="item" key={item}>
                  <p
                    className="tit"
                    onClick={() => setToggleDateLists((prev) => !prev)}
                  >
                    {MonthConverter(selected.split("-")[1])}&nbsp;
                    {selected.split("-")[0]}
                    <span className="material-symbols-rounded">
                      keyboard_arrow_down
                    </span>
                  </p>
                  {toggleDateLists && (
                    <ul className="date-list">
                      {[...period]
                        .filter((x) => x !== selected)
                        .map((time) => {
                          const [y, m] = time.split("-");
                          return (
                            <li
                              key={time}
                              onClick={() => {
                                const reverse = [...period].reverse();
                                const current = reverse.findIndex(
                                  (target) => target === time
                                );
                                setSelected(time);
                                setToggleDateLists(false);
                                ref.current?.slickGoTo(current, true);
                              }}
                            >
                              {MonthConverter(m)} {y}
                            </li>
                          );
                        })}
                    </ul>
                  )}
                </div>
              ))}
            </Slider>
          </div>
        )}

        <div className="draw-list">
          {list.length > 0 ? (
            list.map((item) => <Item key={item.idx} data={item} />)
          ) : (
            <NoData />
          )}
        </div>
      </div>
    </div>
  );
};

export default Draws;

const Item = ({ data }: { data: List }) => {
  const { api } = User();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState({
    numbers: [],
    prize: 0,
  });
  const game = data.game_type === "MM" ? "mega" : "power";
  const fullName = data.game_type === "MM" ? "Mega Millions" : "PowerBall";
  const myNumbers = [
    data.game1,
    data.game2,
    data.game3,
    data.game4,
    data.game5,
  ];
  const white: string[] = [
    result.numbers[0],
    result.numbers[1],
    result.numbers[2],
    result.numbers[3],
    result.numbers[4],
  ];

  useEffect(() => {
    axios
      .get(`${api}/prize/${data.game_type.toLowerCase()}/${data.drawnum}`, {
        headers: {
          "ngrok-skip-browser-warning": "69420",
        },
      })
      .then(({ data }) => {
        setResult({
          numbers: data.data.winningNumber.split(","),
          prize: parseInt(data.data.prize),
        });

        setLoading(false);
      })
      .catch(({ response }) => {});
  }, []);

  const placeConverter = (place: number) => {
    const num = Number(place);

    if (num) {
      switch (num) {
        case 1:
          return "1st";
          break;

        case 2:
          return "2nd";
          break;

        case 3:
          return "3rd";
          break;

        default:
          return `${num}th`;
      }
    } else {
      return "";
    }
  };

  const RankText = (ranks: string | null) => {
    let rankText = "";
    if (ranks) {
      const rankArray = ranks
        .split(",")
        .map((item) => Number(item))
        .sort();

      for (let i = 0; i < rankArray.length; i++) {
        const result = placeConverter(rankArray[i]);

        if (result) {
          if (rankText) {
            rankText = rankText + "/" + result;
          } else {
            rankText = result;
          }
        }
      }
    }

    return rankText;
  };

  const amountMaker = (amount: number) => {
    const inmil = Number((amount / 1000000).toFixed(1));
    let final = "To Be Determined";

    if (inmil > 1000) {
      final = `USD ${(inmil / 1000).toFixed(1)} Billions*`;
    } else if (inmil > 0) {
      final = `USD ${String(inmil)} Millions*`;
    }

    return final;
  };

  const imageSource = () => {
    location.href = `/ticket?code=${data.idx}`;
  };

  return (
    <div className="item">
      <div className="summary">
        <img
          src={game == "mega" ? MegaBallLogo : PowerBallLogo}
          alt={game == "mega" ? "Mega Millions" : "Powerball"}
          className="game-logo"
        />
        <div className="prize-wrap">
          <p className={`game-name ${game}`}>{fullName}</p>
          <p className="prize">
            {loading ? (
              <Skeleton variant="rounded" width={300} height={50} />
            ) : (
              amountMaker(result.prize)
            )}
          </p>
        </div>
        <div className="result-scan-wrap">
          <p>
            {data.win_status === "R"
              ? "Draw Pending"
              : data.win_status === "N"
              ? "No Win"
              : `${RankText(data.win_rank)} place ($${data.prize_usd})`}
          </p>

          <p className="btn style03" onClick={() => imageSource()}>
            <span>View matching ticket</span>
          </p>
        </div>
        <div
          className="btn style01 md"
          onClick={() => (location.href = `/${game}`)}
        >
          Play Again
        </div>
      </div>

      {!open && (
        <div className="btn style03" onClick={() => setOpen((prev) => !prev)}>
          <span>See draw details</span>
          <span className="material-symbols-rounded">expand_more</span>
        </div>
      )}

      {open && (
        <div className="detail">
          {result.numbers.length > 3 && (
            <div className="latest-winning-number">
              <p className="tit">Winning Numbers</p>
              <div className="ball-wrap">
                {result.numbers.map((item, index) =>
                  index === 5 ? (
                    <div
                      key={`${data.drawnum}_${item}_${index}`}
                      className={`ball ${game}`}
                    >
                      {item}
                    </div>
                  ) : (
                    <div
                      key={`${data.drawnum}_${item}_${index}`}
                      className="ball"
                    >
                      {item}
                    </div>
                  )
                )}
              </div>
              <p className="multi">Multiplier 3x</p>
            </div>
          )}

          <div className="my-ball-info-wrap">
            <div className="ball-list">
              {myNumbers.map((num, index) =>
                num ? (
                  <div className="ball-wrap" key={`${index}_${num}`}>
                    <p className="num">
                      {index + 1 < 10 ? `0${index + 1}` : index + 1}
                    </p>
                    {num?.split(",").map((item, i) =>
                      i === 5 ? (
                        <div
                          key={`${data.drawnum}_${index}_${item}}_${i}`}
                          className={`ball ${game} ${
                            result.numbers[5] == item ? "matching" : ""
                          }`}
                        >
                          {item}
                        </div>
                      ) : (
                        <div
                          key={`${data.drawnum}_${index}_${item}}_${i}`}
                          className={`ball ${
                            white.includes(item) ? "matching" : ""
                          } `}
                        >
                          {item}
                        </div>
                      )
                    )}
                  </div>
                ) : (
                  <></>
                )
              )}
            </div>
            <ul>
              <li>
                Total: ${data.game_cnt * 7}, Draw:&nbsp;
                {new Date(data.auDrawTime).toLocaleString("en-AU")}
              </li>

              <li>
                Purchase:&nbsp;
                {new Date(data.created_at).toLocaleString("en-AU")}
              </li>
            </ul>
          </div>
          <div className="btn style03" onClick={() => setOpen((prev) => !prev)}>
            <span>Hide draw details</span>
            <span className="material-symbols-rounded">expand_less</span>
          </div>
        </div>
      )}
    </div>
  );
};
