import LastWinningResult from "../components/sub/lastWinningResult";
import CountDown from "../components/common/countdown";
import How from "./howToPlay";
import Login from "../components/common/login";
import ChooseNumbers from "../components/game/chooseNumbers";
import ErrorPage from "./errorPage";
import Lottie from "lottie-react";
import Standard from "../components/game/myNumber";

// img
import StarsMega from "../img/sub/stars-mega.json";
import MegaLogo from "../img/common/mega-white-logo.svg";
import PowerLogo from "../img/common/power-white-logo.svg";

// tools
import User from "../tools/userInfo";
import { reset, update } from "../tools/zustandUpdate";
import { useState, useEffect } from "react";
import axios from "axios";

import { useLotto } from "../App";
import Favorites from "./DESIGN/GAME/favorites";
import MyNumbers from "../components/game/myNumber";
import Swal from "sweetalert2";

interface GameProps {
  type: string;
}

interface GameInfo {
  drawnum: number;
  jackpot: number;
  playdate: string;
  orderTime: string;
  drawdate: number;
}

// interface TabMenu {
//   menu: string;
//   title: string;
// }

const Game = ({ type }: GameProps) => {
  const { api, token, info, setTotalInfo } = User();
  const { lottery } = useLotto();

  const isType = (type: string) => {
    if (type === "mega") {
      return lottery[1];
    } else {
      return lottery[0];
    }
  };

  // Tab menu option = default : myNum
  const [menu, setMenu] = useState("myNum");

  // const tab: TabMenu[] = [
  //   { menu: "myNum", title: "Standard" },
  //   { menu: "fav", title: "Favourites" },
  // ];

  // 게임정보 초기상태 정의
  const [gameInfo, setGameInfo] = useState<GameInfo>({
    drawnum: 0,
    jackpot: 0,
    playdate: "",
    orderTime: "",
    drawdate: 0,
  });

  // modal open/close status
  const [open, setOpen] = useState(false);

  /** 게임 정보 호출 (날짜, 주문시간, 추첨날짜, 추첨번호, 당첨금) */
  const purchaseInfoAxios = (type: string) => {
    const game = type === "mega" ? "mm" : "pb";

    axios
      .get(`${api}/purchase/${game}`, {
        headers: {
          "ngrok-skip-browser-warning": "69420",
        },
      })
      .then(({ data }) => {
        const Data = data.data[0];

        if (data.message === "success") {
          setGameInfo({
            drawnum: Data.drawnum,
            jackpot: Number(Data.exchange) * Data.jackpot,
            playdate: Data.playdate,
            orderTime: Data.auOrderTime,
            drawdate: Data.isDrawdate,
          });
        }
      });
  };

  // component mount -> run
  useEffect(() => {
    // URL 추출 및 초기화
    const search = location.search; // ?tab=quick
    const split = search.replace("?", "").split("&"); // tab=quick
    const tab = split[0].split("=")[1]; // [tab,quick]

    purchaseInfoAxios(location.pathname.replace("/", ""));

    // URL tab setting
    if (tab) {
      setMenu(tab);
    }

    const empty = {
      address: "",
      birth: "",
      cash: 0,
      city: "",
      country: "",
      createdAt: "",
      email: "",
      firstName: "",
      gender: "",
      lastName: "",
      level: 0,
      marketing: "",
      notify: "",
      number: "",
      phone: "",
      unit: "",
      provider: "",
      prize: 0,
      totalPrize: 0,
    };

    // has token update user info
    if (token) {
      const payload = JSON.parse(atob(token.split(".")[1]));
      const origin = payload.iss;

      update(api, token, setTotalInfo);

      if (!origin.includes("/login") && !origin.includes("/refresh")) {
        reset(setTotalInfo);
      }
    } else {
      if (JSON.stringify(empty) !== JSON.stringify(info)) {
        reset(setTotalInfo);
      }
    }
  }, []);

  /** 금액 포맷 */
  const amountMaker = (amount: number) => {
    // 백만 단위 변환
    const inmil = Number((amount / 1000000).toFixed(1));
    let final = 0;

    if (inmil > 1000) {
      const inbil = inmil / 1000;
      final = Math.floor(inbil * 100) / 100;
    } else if (inmil > 0) {
      final = inmil;
    }

    return final;
  };

  const tabNavigate = (menu: string) => {
    if (!token && menu === "fav") {
      Swal.fire({
        icon: "warning",
        title: "Members Only",
        text: "This method is only for Lottery Cluster members",
        allowOutsideClick: false,
      });
      return;
    }
    if (menu === "myNum") {
      location.href = `/${type}?tab=myNum`;
      return;
    }
    if (token) {
      location.href = `/${type}?tab=${menu}`;
    }
  };

  return (
    <div className={`sub game ${type}`}>
      {/* Game Top Banner */}
      <div className={`game-top-area ${type === "mega" ? "mega" : "power"}`}>
        <div className="game-info-area">
          <p className="name">
            {type === "mega" ? "Mega Millions" : "Powerball"}
          </p>
          <p className="prize">
            {isType(type)?.isDrawdate ? "" : <span className="unit">$ </span>}
            <span className="num">
              {isType(type)?.isDrawdate
                ? "Pending"
                : `${amountMaker(
                    isType(type)?.jackpot * Number(isType(type)?.exchange)
                  ).toLocaleString("au")}`}
            </span>
            <span className="unit">
              {isType(type)?.isDrawdate
                ? ""
                : isType(type)?.jackpot * Number(isType(type)?.exchange) >=
                  1000000000
                ? " Billion"
                : " Million"}
            </span>
          </p>
          <p className="time">
            <CountDown
              date={isType(type)?.auOrderTime}
              pending={isType(type)?.isDrawdate}
            />
          </p>
        </div>
        <div className="img-area">
          <Lottie animationData={StarsMega} className="stars" loop={true} />
          <img
            src={`${type === "mega" ? MegaLogo : PowerLogo}`}
            alt={`${type === "mega" ? "Mega Millions" : "Powerball"}`}
            className="logo"
          />
        </div>
      </div>
      {/* <div className="top-info-area">
        <ul className="tab style01">
          {tab.map((it, idx) => (
            <li
              key={idx}
              className={menu === it.menu ? "current" : ""}
              onClick={() => tabNavigate(it.menu)}
            >
              {it.title}
            </li>
          ))}
        </ul>
      </div> */}

      {menu === "myNum" ? (
        <MyNumbers type={type} setLogin={setOpen} gameInfo={gameInfo} />
      ) : menu === "fav" ? (
        <Favorites type={type} setLogin={setOpen} gameInfo={gameInfo} />
      ) : menu === "how" ? (
        <How type={type} />
      ) : (
        <ErrorPage />
      )}

      {/* <LastWinningResult
          type={location.pathname.slice(1, location.pathname.length)}
        /> */}
      <Login open={open} setOpen={setOpen} />
    </div>
  );
};

export default Game;
