import React, { useState } from "react";
// img
import NavBarIcon1 from "./icon_navBar_menu.svg";
import NavBarIcon2 from "./icon_navBar_mega.svg";
import NavBarIcon3 from "./icon_navBar_power.svg";
import NavBarIcon4 from "./icon_navBar_results.svg";
import NavBarIcon5 from "./icon_navBar_mypage.svg"; //로그인 후
import { useLotto } from "../../../App";
import { useNavigate } from "react-router-dom";

interface SideMenu {
  title: string;
  icon: any;
  href: string;
}

const Menubar: SideMenu[] = [
  { title: "Mega Millions", icon: NavBarIcon2, href: "/mega" },
  { title: "Powerball", icon: NavBarIcon3, href: "/power" },
  { title: "Results", icon: NavBarIcon4, href: "/result" },
  { title: "MyPage", icon: NavBarIcon5, href: "/mypage" },
];

const NavBar = () => {
  const { navOpen, setNavOpen } = useLotto();
  const [focus, setFocus] = useState<string>("");
  const navigate = useNavigate();

  const clickMenu = (target: string, href: string) => {
    navigate(href);
    setFocus(target);
    setNavOpen(false);
  };

  return (
    <div className="navbar">
      <div className="navbar-wrap">
        <div
          className="navbar-item"
          onClick={() => setNavOpen((prev) => !prev)}
        >
          <div className="navitem-icon">
            <img src={NavBarIcon1} alt="Menu" />
          </div>
          <div className="item-label">Menu</div>
        </div>

        {Menubar.map((it, idx) => (
          <div
            key={idx}
            className={`navbar-item ${focus === it.title ? "active" : ""}`}
            onClick={() => clickMenu(it.title, it.href)}
          >
            <div className="navitem-icon">
              <img src={it.icon} alt="Results" />
            </div>
            <div className="item-label">{it.title}</div>
          </div>
        ))}

        {/* <div className="navbar-item">
            <div className="navitem-icon">
              <img src={NavBarIcon2} alt="Mega Millions" />
            </div>
            <div className="item-label">Mega Millions</div>
          </div>
          <div className="navbar-item">
            <div className="navitem-icon">
              <img src={NavBarIcon3} alt="Power ball" />
            </div>
            <div className="item-label">Powerball</div>
          </div>
          <div className="navbar-item">
            <div className="navitem-icon">
              <img src={NavBarIcon4} alt="Results" />
            </div>
            <div className="item-label">Results</div>
          </div>
          <div className="navbar-item active">
            <div className="navitem-icon">
              <img src={NavBarIcon5} alt="Mypage" />
            </div>
            <div className="item-label">Mypage</div>
          </div> */}
      </div>
    </div>
  );
};

export default NavBar;
