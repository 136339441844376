// img
import AppDownImg01 from "../../img/common/app-img01.svg";
import GooglePlay from "../../img/common/googleplay-img.svg";
import AppStrore from "../../img/common/appstore-img.svg";
import Visa from "../../img/sub/visa-ico.svg";
import Paypal from "../../img/sub/paypal-ico.svg";
import Mastercard from "../../img/sub/mastercard-ico.svg";
import Payid from "../../img/sub/payid-ico.svg";
import Bpay from "../../img/sub/bpay-ico.svg";
import Paysafe from "../../img/sub/paysafe-ico.svg";
import Applepay from "../../img/sub/applepay-ico.svg";

const Footer = () => {
  const handleScrollUp = (path: string) => {
    window.location.href = `/${path}`;
  };

  return (
    <footer className="footer">
      <div className="top-area">
        {/* <div className="app-download">
          <img src={AppDownImg01} alt="Lottery Cluster App Download" />
          <div className="btn-wrap">
            <div className="btn">
              <img src={GooglePlay} alt="Google Play" />
            </div>
            <div className="btn">
              <img src={AppStrore} alt="App Store" />
            </div>
          </div>
        </div> */}
        <p>Safe and secure payments</p>
        <div className="img-wrap">
          <img src={Visa} alt="Visa" />
          <img src={Mastercard} alt="Mastercard" />
          <img src={Paypal} alt="PayPal" />
          <img src={Bpay} alt="BPAY" />
          <img src={Paysafe} alt="Paysafecard" />
          <img src={Payid} alt="Pay id" />
          <img src={Applepay} alt="Apple Pay" />
        </div>
      </div>
      <div className="bottom-area">
        <ul className="footer-nav">
          <li onClick={() => handleScrollUp("about")}>About us</li>
          <li onClick={() => handleScrollUp("email")}>Contact us</li>
          <li onClick={() => handleScrollUp("faq")}>FAQ</li>
          <li onClick={() => handleScrollUp("result")}>Results</li>
          <li onClick={() => handleScrollUp("terms")}>Terms and Conditions</li>
          <li onClick={() => handleScrollUp("privacy")}>Privacy</li>
        </ul>
        <p className="addr">
          Registered Office Hillside (Gibraltar Sports) LP (registration number
          198), Unit 1.1, First Floor, Waterport Place, 2 Europort Avenue
          Gibraltar. <br />
          Hillside (Gibraltar Sports) LP is licensed by the Government of
          Gibraltar and regulated by the Gibraltar Gambling Commissioner (RGL
          number 130).
        </p>
        <p className="copyright">
          © 2024 Lottery Cluster. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
