// tools
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  NumberGenerator,
  SysNumberGenerator,
} from "../../tools/numberGenerator";
import { RandomNumber } from "../../tools/random";
import { useLotto } from "../../App";
import { customAlphabet } from "nanoid";
import NumberOnly from "../../tools/numberOnly";
import Swal from "sweetalert2";
import axios from "axios";

// ui
import FormControlLabel from "@mui/material/FormControlLabel";
import Tooltip from "@mui/material/Tooltip";
import Switch from "@mui/material/Switch";
import Lottie from "lottie-react";
import {
  Checkbox,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Select,
} from "@mui/material";

// img
import FavIco from "../../img/sub/favorite-ico.svg";
import Lightning from "../../img/sub/lightning.json";

// comp
import GameItem from "./gameItem";
import User from "../../tools/userInfo";
import SystemItem from "./systemItem";
import { update } from "../../tools/zustandUpdate";

// type
interface GameInfo {
  drawnum: number;
  jackpot: number;
  playdate: string;
  orderTime: string;
}

interface QuickProps {
  type: string;
  setLogin: Dispatch<SetStateAction<boolean>>;
  gameInfo: GameInfo;
}

export interface Ball {
  white: number;
  special: number;
}

export interface BallSet {
  [key: string]: Ball;
  mega: Ball;
  power: Ball;
}

export interface Nums {
  num: number[];
  method: string;
  q: boolean;
}

interface GameMode {
  title: string;
  mode: string;
}

// constant
const BallSet: BallSet = {
  mega: {
    white: 70,
    special: 25,
  },
  power: {
    white: 69,
    special: 26,
  },
};

export interface SystemMenu {
  idx: number;
  name: string;
  code: string;
  description: string;
  regular: number;
  special: number;
  games: number;
}

export interface SystemNums {
  num: number[];
  special: number[];
  method: string;
  systemInfo: SystemMenu;
  q: boolean;
}

const gameMode: GameMode[] = [
  { title: "Quick picks", mode: "Q" },
  { title: "Choose numbers", mode: "C" },
  { title: "Systems", mode: "S" },
  { title: "Favourites", mode: "F" },
];
const gameSets: number[] = [1, 2, 3, 5, 10, 20];
const gameWeeks: number[] = [1, 2, 3, 5, 10, 20];

const MyNumber = ({ type, setLogin, gameInfo }: QuickProps) => {
  // User custom
  const { info, api, token, setTotalInfo } = User();
  const { lottery, limitCart } = useLotto();

  // stat
  const [mode, setMode] = useState<string>("Q");
  const [multi, setMulti] = useState<boolean>(false);
  const [weeks, setWeeks] = useState<number>(1);
  const [drawOption, setDrawOption] = useState<boolean>(false);

  // standard
  const [cnt, setCnt] = useState<number>(4);
  const [game, setGame] = useState<Nums[]>([]);
  const [lastGame, setLastGame] = useState<boolean>(false);

  // system
  const [sysMenu, setSysMenu] = useState<SystemMenu[]>([]);
  const [selectMenu, setSelectMenu] = useState<SystemMenu | null>(null);
  const [sysGame, setSysGame] = useState<SystemNums[]>([]);

  // useEffect : Mode Change
  useEffect(() => {
    const set = BallSet[type];

    if (mode === "C") {
      const clearedGameArray = game.map(() => ({
        num: [0, 0, 0, 0, 0, 0],
        method: "M",
        q: false,
      }));
      setGame(clearedGameArray);
    } else if (mode === "Q") {
      const randomGameArray = game.map(() => {
        const white = NumberGenerator(set.white, []);
        const special = RandomNumber(set.special);
        return {
          num: [...white.sort((a, b) => a - b), special],
          method: "A",
          q: false,
        };
      });
      setGame(randomGameArray);
    }
  }, [mode]);

  // useEffect : Cnt Change
  useEffect(() => {
    if (mode === "S") {
    } else {
      const set = BallSet[type];
      if (mode === "C") {
        const newGameArray = Array(cnt - game.length).fill({
          num: [0, 0, 0, 0, 0, 0],
          method: "M",
          q: false,
        });
        setGame((prevGame) => [...prevGame, ...newGameArray]);
      } else {
        const newGameArray = Array(cnt - game.length)
          .fill(() => {
            const white = NumberGenerator(set.white, []);
            const special = RandomNumber(set.special);
            return {
              num: [...white.sort((a, b) => a - b), special],
              method: "A",
              q: false,
            };
          })
          .map((fn) => fn());
        setGame((prevGame) => [...prevGame, ...newGameArray]);
      }

      if (cnt === 1) {
        setLastGame(true);
      } else {
        setLastGame(false);
      }
    }
  }, [cnt]);

  // gameMode
  useEffect(() => {
    if (mode === "S") {
      const isType = type === "mega" ? "mm" : "pb";

      axios
        .get(`${api}/product/system/${isType}`, {
          headers: {
            "ngrok-skip-browser-warning": "69420",
          },
        })
        .then((res) => {
          setSysMenu(res.data.data);
          setSelectMenu(res.data.data[0]);
        })
        .catch((error) => console.log(error));
    } else {
      return;
    }
  }, [mode]);

  useEffect(() => {
    setSysGame([]);
  }, [selectMenu]);

  // func
  const countChange = (value: number) => {
    if (cnt > 100 - value) {
      Swal.fire({
        title: "Error",
        text: "You cannot exceed 100 games per purchase.",
        icon: "error",
        allowOutsideClick: false,
      });
      return;
    }
    if (cnt < 101) {
      setCnt((prev) => prev + value);
    }
  };

  const addSysGame = () => {
    if (selectMenu) {
      const newSys = Array(selectMenu.regular).fill(0);
      const newSpecial =
        selectMenu.code === "GUA" ? [0] : Array(selectMenu.special).fill(0);

      setSysGame((prev) => [
        ...prev,
        {
          num: newSys,
          special: newSpecial,
          method: "M",
          q: false,
          systemInfo: { ...selectMenu },
        },
      ]);
    }
  };

  const handleClear = (
    target: number,
    isSystem: boolean,
    isPlaying: boolean
  ) => {
    if (isPlaying) {
      return;
    } else {
      if (!isSystem) {
        setGame((prev) =>
          prev.map((item, idx) =>
            idx === target ? { ...item, num: [0, 0, 0, 0, 0, 0] } : item
          )
        );
      } else {
        setSysGame((prev) =>
          prev.map((item, idx) =>
            idx === target
              ? {
                  ...item,
                  num: Array(item.systemInfo.regular).fill(0),
                  special: Array(item.systemInfo.special).fill(0),
                }
              : item
          )
        );
      }
    }
  };

  const handleAllClear = () => {
    Swal.fire({
      icon: "question",
      title: "Do you want to clear all game numbers?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      confirmButtonColor: "#af7af1",
      cancelButtonColor: "#876aab",
    }).then((result) => {
      if (result.isConfirmed) {
        if (mode === "S") {
          setSysGame((prev) =>
            prev.map((it) => ({
              ...it,
              num: Array(it.systemInfo.regular).fill(0),
              special: [0],
            }))
          );
        } else {
          setGame((prev) =>
            prev.map((it) => ({
              ...it,
              num: [0, 0, 0, 0, 0, 0],
            }))
          );
        }
      } else {
        return;
      }
    });
  };

  const handleDelete = (
    target: number,
    isSystem: boolean = false,
    isPlaying: boolean
  ) => {
    if (isPlaying) {
      return;
    } else {
      Swal.fire({
        icon: "question",
        title: "Are you sure you want to delete this game?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        confirmButtonColor: "#af7af1",
        cancelButtonColor: "#876aab",
      }).then((result) => {
        if (result.isConfirmed) {
          if (!isSystem) {
            if (target >= 0 && target < game.length && cnt > 1) {
              setGame((prev) => prev.filter((_, idx) => idx !== target));
              setCnt((prev) => prev - 1);
            } else {
              console.error(`Invalid target index: ${target}`);
            }
          } else {
            if (target >= 0 && target < sysGame.length) {
              setSysGame((prev) => prev.filter((_, idx) => idx !== target));
            } else {
              console.error(`Invalid target index: ${target}`);
            }
          }
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          return;
        }
      });
    }
  };

  const NumberGenerator2 = (
    maxNumber: number,
    usedNumbers: number[] = [],
    count: number = 5
  ) => {
    const numbers: number[] = [];
    while (numbers.length < count) {
      const rand = Math.floor(Math.random() * maxNumber) + 1;
      if (!numbers.includes(rand) && !usedNumbers.includes(rand)) {
        numbers.push(rand);
      }
    }
    return numbers;
  };

  /** 빈값 채우기 */
  const fillEmptySlots = (
    whiteBalls: number[],
    specialBall: number | number[],
    type: keyof BallSet,
    isSystem: boolean = false,
    regularCount: number = 5,
    specialCount: number = 1
  ) => {
    const set = BallSet[type];

    // 이미 선택된 공을 제외
    const usedWhiteBalls = whiteBalls.filter((num) => num !== 0);

    // 남은 자리랜덤으로 생성 (이미 선택된 공은 제외)
    let newWhiteBalls = NumberGenerator2(
      set.white,
      usedWhiteBalls,
      regularCount
    ).slice(0, regularCount - usedWhiteBalls.length);

    // 비어 있는 공만 채움
    const filledWhiteBalls = whiteBalls.map((num) =>
      num === 0 ? newWhiteBalls.pop()! : num
    );

    // 스페셜 공 처리
    let filledSpecialBall;
    if (isSystem && Array.isArray(specialBall)) {
      const usedSpecialBalls = specialBall.filter((num) => num !== 0);
      let newSpecialBalls = NumberGenerator2(
        set.special,
        usedSpecialBalls,
        specialCount
      ).slice(0, specialCount - usedSpecialBalls.length);
      filledSpecialBall = specialBall.map((num) =>
        num === 0 ? newSpecialBalls.pop()! : num
      );
    } else {
      filledSpecialBall =
        specialBall === 0 ? RandomNumber(set.special) : specialBall;
    }

    return {
      whiteBalls: filledWhiteBalls.sort((a, b) => a - b),
      specialBalls: filledSpecialBall,
    };
  };

  /** 자동 선택 */
  const handleQuickPick = (
    target: number,
    isSystem: boolean = false,
    isPlay: boolean
  ) => {
    if (isPlay) {
      return;
    } else {
      if (isSystem) {
        const allSelected =
          sysGame[target].num.every((num) => num !== 0) &&
          sysGame[target].special[0] !== 0;
        const noSelected =
          sysGame[target].num.every((num) => num === 0) &&
          sysGame[target].special[0] === 0;

        if (!noSelected && !allSelected) {
          setSysGame((prevGame) =>
            prevGame.map((gameItem, idx) => {
              if (idx === target) {
                const { whiteBalls, specialBalls } = fillEmptySlots(
                  gameItem.num,
                  gameItem.special,
                  type,
                  true,
                  gameItem.systemInfo.regular,
                  gameItem.systemInfo.special
                );
                return {
                  ...gameItem,
                  num: whiteBalls,
                  special: specialBalls as number[],
                  method: "A",
                };
              }
              return gameItem;
            })
          );
        } else {
          const intervalId = setInterval(() => {
            setSysGame((prevGame) =>
              prevGame.map((gameItem, idx) => {
                if (idx === target) {
                  const white = NumberGenerator2(
                    BallSet[type].white,
                    [],
                    gameItem.systemInfo.regular
                  );
                  const special = RandomNumber(BallSet[type].special);
                  return {
                    ...gameItem,
                    num: [...white.sort((a, b) => a - b)],
                    special: [special],
                    method: "A",
                    q: true,
                  };
                }
                return gameItem;
              })
            );
          }, 100);

          setTimeout(() => {
            clearInterval(intervalId);
            setSysGame((prevGame) =>
              prevGame.map((gameItem, idx) => {
                if (idx === target) {
                  return {
                    ...gameItem,
                    q: false,
                  };
                }
                return gameItem;
              })
            );
          }, 1000);
        }
      } else {
        const allSelected = game[target].num.every((num) => num !== 0);
        const noSelected = game[target].num.every((num) => num === 0);

        if (!noSelected && !allSelected) {
          // 선택된 공이 일부만 있을 때 -> 비어있는 공만 채움
          setGame((prevGame) =>
            prevGame.map((gameItem, idx) => {
              if (idx === target) {
                const { whiteBalls, specialBalls } = fillEmptySlots(
                  gameItem.num.slice(0, 5),
                  gameItem.num[5],
                  type
                );
                return {
                  ...gameItem,
                  num: [...whiteBalls, specialBalls as number],
                  method: "A",
                };
              }
              return gameItem;
            })
          );
        } else {
          // 선택된 공이 없거나 모든 공이 이미 선택된 경우 -> 랜덤으로 반복해서 채움

          const intervalId = setInterval(() => {
            setGame((prevGame) =>
              prevGame.map((gameItem, idx) => {
                if (idx === target) {
                  const white = NumberGenerator(BallSet[type].white, []);
                  const special = RandomNumber(BallSet[type].special);
                  return {
                    ...gameItem,
                    num: [...white.sort((a, b) => a - b), special],
                    method: "A",
                    q: true,
                  };
                }
                return gameItem;
              })
            );
          }, 100);

          setTimeout(() => {
            clearInterval(intervalId);
            setGame((prevGame) =>
              prevGame.map((gameItem, idx) => {
                if (idx === target) {
                  return {
                    ...gameItem,
                    q: false,
                  };
                }
                return gameItem;
              })
            );
          }, 1000);
        }
      }
    }
  };

  // api tools
  const gameType = () => {
    if (type === "mega") {
      return lottery[1];
    } else {
      return lottery[0];
    }
  };
  const isMode = mode === "Q" ? "Q" : mode === "C" ? "C" : "S";
  const isOption = mode === "Q" || mode === "C" ? "STD" : "SYS";
  const total = mode !== "S" ? game.length : sysGame.length;

  const fullSysGame = sysGame.filter((it) => !it.num.includes(0));
  const sysTotal = fullSysGame.reduce(
    (total, it) => total + it.systemInfo.games,
    0
  );

  const generalMultiplier = multi ? 10.45 : 7;
  const systemMultiplier = multi ? 10.45 : 7;

  const totalAmount = total * generalMultiplier * weeks;
  const sysTotalAmount = sysTotal * systemMultiplier * weeks;

  // api
  const addCart = () => {
    let incompleteGame = false;
    if (mode === "S") {
      incompleteGame = sysGame.some(
        (g) =>
          g.num.includes(0) ||
          (g.special.includes(0) && g.systemInfo.special > 0)
      );
    } else {
      incompleteGame = game.some((g) => g.num.includes(0));
    }

    const data = {
      productCode: gameType().type,
      methodCode: isOption,
      systemCode:
        isMode === "Q"
          ? "QPK"
          : isMode === "C"
          ? "CHN"
          : isMode === "S"
          ? selectMenu?.code
          : "",
      multiplier: multi ? "Y" : "N",
      draws: weeks,
      ticketQty: isMode === "S" ? sysTotal : total,
      subscription: drawOption ? "Y" : "N",
      totalAmount: isMode === "S" ? sysTotalAmount : totalAmount,
      nums:
        mode === "S" && selectMenu?.code === "GUA"
          ? sysGame.map((it) => {
              return {
                num: [...it.num, "G"],
                method: it.method,
              };
            })
          : mode === "S" && selectMenu?.code === "PIC"
          ? sysGame.map((it) => {
              return {
                num: [
                  it.num[0],
                  it.num[1],
                  it.num[2],
                  it.num[3],
                  "G",
                  it.special[0],
                ],
                method: it.method,
              };
            })
          : mode === "S"
          ? sysGame.map((it) => {
              return {
                num: [...it.num, it.special[0]],
                method: it.method,
              };
            })
          : game.map((it) => {
              const { q, ...rest } = it;
              return {
                ...rest,
              };
            }),
    };

    const dataArray = [];
    dataArray.push(data);

    if (info.cart >= limitCart) {
      Swal.fire({
        icon: "warning",
        title: "You cannot purchase more than 20 games in the cart.",
      });
    } else if (total === 0) {
      Swal.fire({
        icon: "warning",
        title: "has no games.",
      });
    } else {
      if (incompleteGame) {
        Swal.fire({
          title: "There is an incomplete game.",
          icon: "warning",
          allowOutsideClick: false,
        });
        return;
      } else if (token.length > 0) {
        axios
          .post(`${api}/cart`, dataArray, {
            headers: {
              "ngrok-skip-browser-warning": "69420",
              Authorization: "bearer " + token,
            },
          })
          .then((res) => console.log(res))
          .catch((err) => {
            console.error(
              "Error:",
              err.response ? err.response.status : err.message
            );
          });

        Swal.fire({
          icon: "success",
          title: "Completed Add to Cart!",
          allowOutsideClick: false,
          didClose: () => {
            if (mode === "S") {
              setSysGame([]);
            } else {
              setGame([]);
              setCnt(0);
            }

            axios
              .get(`${api}/auth/me`, {
                headers: {
                  "ngrok-skip-browser-warning": "69420",
                  Authorization: "bearer " + token,
                },
              })
              .then((res) => update(api, token, setTotalInfo));
          },
        });
      } else if (token.length === 0) {
        // 비회원 처리 로직 추가 필요
      }
    }

    console.log(data);
  };

  const addFavorite = () => {
    if (!token) {
      Swal.fire({
        icon: "warning",
        title: "Members Only",
        text: "This method is only for Lottery Cluster members",
        allowOutsideClick: false,
      });
    } else {
      const data = {
        code: gameType().type,
        option: isMode === "Q" ? "Q" : "C",
        quantity: total,
        nums: game.map((it) => {
          return {
            ...it,
          };
        }),
      };

      axios
        .post(`https://3c60-13-209-180-206.ngrok-free.app/v1/favorite`, data, {
          headers: {
            "ngrok-skip-browser-warning": "69420",
            Authorization: "bearer " + token,
          },
        })
        .then((res) =>
          Swal.fire({
            icon: "success",
            title: "Sucess to add Favorites",
            timer: 2000,
          })
        )
        .catch((error) => console.error(error));
    }
  };

  return (
    <div className="standard-wrap">
      <div className="standard">
        <div className="step method">
          <div className="item">
            <h4 className="tit">1. Select game mode</h4>
            <div className="btn-wrap">
              {gameMode.map((it, idx) => (
                <div
                  key={idx}
                  className={`btn ${it.mode === mode ? "picked" : ""}`}
                  onClick={() => setMode(it.mode)}
                >
                  {it.title}
                </div>
              ))}
            </div>
          </div>
        </div>
        {mode === "Q" || mode === "C" ? (
          <div className="step count">
            <h4 className="tit">2. Select game quantity</h4>
            <div className="btn-wrap">
              {gameSets.map((it, idx) => (
                <div key={idx} className="btn" onClick={() => countChange(it)}>
                  {it}
                </div>
              ))}
            </div>
          </div>
        ) : mode === "S" ? (
          <div className="step sys-type">
            <h4 className="tit">
              2. Select Lottery Systems
              <Tooltip
                title={
                  <>
                    <p>System types</p>
                  </>
                }
              >
                <span className="material-symbols-rounded">info</span>
              </Tooltip>
            </h4>
            <FormControl fullWidth>
              <InputLabel id="lottery-system">Lottery Systems</InputLabel>
              <Select
                labelId="lottery-system"
                label="Lottery Systems"
                defaultValue={0}
              >
                {sysMenu.map((it, idx) => (
                  <MenuItem
                    key={it.idx}
                    value={idx}
                    onClick={() => setSelectMenu(it)}
                  >
                    {it.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <p className="exp" style={{ color: "#4f5253", marginTop: "1rem" }}>
              {selectMenu?.description}
            </p>
          </div>
        ) : (
          ""
        )}

        {mode === "Q" || mode === "S" || mode === "C" ? (
          <>
            <div className="step ticket">
              <div className="tit-wrap">
                {mode === "S" && (
                  <h4 className="tit">3. Choose your numbers</h4>
                )}
                <div className="btn-wrap">
                  <div className="btn fav" onClick={() => addFavorite()}>
                    My favorite
                    <img src={FavIco} alt="Favorite numbers" />
                  </div>
                  <div className="btn" onClick={handleAllClear}>
                    Clear all
                    <span className="material-symbols-rounded">delete</span>
                  </div>
                </div>
              </div>
              {/* gameList */}
              <div className={`ticket-wrap ${mode === "S" ? "sys" : ""}`}>
                {mode === "Q" || mode === "C" ? (
                  game.map((it, idx) => (
                    <GameItem
                      key={idx}
                      id={Number(idx) + 1}
                      type={type}
                      mode={mode}
                      Nums={it}
                      game={game}
                      setGame={setGame}
                      handleClear={() => handleClear(idx, false, it.q)}
                      handleDelete={() => handleDelete(idx, false, it.q)}
                      handleQuickPick={() => handleQuickPick(idx, false, it.q)}
                      lastGame={lastGame}
                      playing={it.q}
                    />
                  ))
                ) : mode === "S" ? (
                  <>
                    {sysGame.map((it, idx) => (
                      <SystemItem
                        key={idx}
                        nums={it}
                        id={Number(idx) + 1}
                        type={type}
                        token={token}
                        selectMenu={selectMenu}
                        game={sysGame}
                        setGame={setSysGame}
                        handleClear={() => handleClear(idx, true, it.q)}
                        handleDelete={() => handleDelete(idx, true, it.q)}
                        handleQuickPick={() => handleQuickPick(idx, true, it.q)}
                        playing={it.q}
                      />
                    ))}

                    {/* Add a game 버튼을 map 바깥에 렌더링 */}
                    <div
                      className="btn style01 md spc add-game"
                      onClick={() => addSysGame()}
                    >
                      <span className="material-symbols-rounded">
                        add_circle
                      </span>
                      Add a game
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className={`step multiplier ${multi ? "on" : ""}`}>
              {multi && (
                <Lottie
                  animationData={Lightning}
                  className="lightning"
                  loop={false}
                />
              )}
              <h4 className="tit">3. Megaplier</h4>
              <div className="layout">
                <FormControlLabel
                  control={
                    <Switch onChange={() => setMulti((prev) => !prev)} />
                  }
                  label="Multiplier added"
                />

                <div className="txt-wrap">
                  <p className="txt">$ 3.45 per combination</p>
                  <p className="exp">
                    Boost the potential winnings up to 5 times on all
                    non-jackpot prizes!
                  </p>
                </div>
              </div>
            </div>
            <div className="step subscribe">
              <div className="tit-wrap">
                <h4 className="tit">
                  4. How many draws
                  <Tooltip
                    title={
                      <>
                        <p>
                          How many draws would you like to use these numbers
                          for?
                        </p>
                      </>
                    }
                  >
                    <span className="material-symbols-rounded">info</span>
                  </Tooltip>
                </h4>
              </div>
              <div className="btn-wrap">
                {!drawOption ? (
                  gameWeeks.map((it, idx) => (
                    <div
                      key={idx}
                      className={`btn ${it === weeks ? "picked" : ""}`}
                      onClick={() => setWeeks(it)}
                    >
                      {it}
                    </div>
                  ))
                ) : (
                  <div className="btn picked">1</div>
                )}
              </div>

              <FormControlLabel
                control={
                  <Switch
                    onChange={() => {
                      setDrawOption((prev) => !prev);
                      setWeeks(1);
                    }}
                  />
                }
                sx={{ marginTop: "12px" }}
                label="Buy All Draws / Stop at Jackpot"
              />
              {!drawOption ? (
                <p className="exp">
                  Would you like to continue purchasing the remaining draws even
                  if there is a jackpot winner during the series, or would you
                  prefer to stop purchasing after a winner is announced?
                </p>
              ) : (
                <p className="exp">
                  Your ticket will be automatically renewed for each draw until
                  the jackpot is won. You can cancel at any time.
                </p>
              )}
            </div>
          </>
        ) : (
          ""
        )}
      </div>
      {mode === "Q" || mode === "S" || mode === "C" ? (
        <div className="pick-info-wrap">
          <ul className="pick-info">
            <li>
              <span className="tit">Total count</span>
              {mode === "S" ? sysTotal : total}
            </li>
            <li>
              <span className="tit">option</span>
              <span className="txt">
                {mode !== "S" ? "Standard" : "System"}
              </span>
            </li>
            <li>
              <span className="tit">Mulitiplier</span>
              <span className="txt">{multi ? "O" : "X"}</span>
            </li>
            <li>
              <span className="tit">Draw</span>
              <span className="txt">{weeks}</span>
            </li>
            <li className="total">
              <span className="tit">Total</span>
              <span className="txt">
                $ &nbsp;
                {(() => {
                  if (multi && mode !== "S") {
                    // 멀티 && 일반 게임
                    return totalAmount.toFixed(2);
                  } else if (!multi && mode !== "S") {
                    // 싱글 && 일반 게임
                    return totalAmount.toFixed(2);
                  } else if (multi && mode === "S") {
                    // 멀티 && 시스템 게임
                    return sysTotalAmount.toFixed(2);
                  } else if (!multi && mode === "S") {
                    // 싱글 && 시스템 게임
                    return sysTotalAmount.toFixed(2);
                  }
                })()}
              </span>
            </li>
            <div className="btn style01 md spc" onClick={addCart}>
              <span>Add to cart</span>
              <span className="material-symbols-rounded">shopping_cart</span>
            </div>
          </ul>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default MyNumber;
