// tools
import Swal from "sweetalert2";
import axios from "axios";
import User from "../tools/userInfo";
import { update } from "../tools/zustandUpdate";
import { useEffect, useState } from "react";

// ui
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";

// componemts
import Draws from "../components/mypage/draws";
import History from "../components/mypage/history";
import Settings from "../components/mypage/settings";
import { CustModal } from "../components/common/commonElements";

// img
import BankImg from "../img/sub/bank-logo.svg";
import UserIco from "../img/icon/circle-user-solid.svg";
import MyStat from "../components/mypage/myStat";

interface Category {
  menu: string;
  title: string;
  href: string;
}

const Mypage = () => {
  const { api, token, setTotalInfo, info } = User();
  const [menu, setMenu] = useState("");
  const [open, setOpen] = useState(false);
  const [openWithdraw, setOpenWithDraw] = useState(false);
  const [amount, setAmount] = useState(0);
  const [selectedValue, setSelectedValue] = useState("total");

  const changeView = (type: string) => {
    switch (type) {
      case "draw":
        return <Draws />;
        break;

      case "history":
        return <History />;
        break;

      case "settings":
        return <Settings />;
        break;

      case "mystat":
        return <MyStat />;
    }
  };

  const cate: Category[] = [
    {
      menu: "draw",
      title: "Draws",
      href: "/mypage?tab=draw",
    },
    {
      menu: "history",
      title: "History",
      href: "/mypage?tab=history",
    },
    {
      menu: "settings",
      title: "Settings",
      href: "/mypage?tab=settings",
    },
    { menu: "mystat", title: "My Stat", href: "/mypage?tab=mystat" },
  ];

  if (!token) {
    Swal.fire({
      title: "Members Only",
      text: "This page is only for Lottery Cluster members",
      icon: "error",
      allowOutsideClick: false,
      didClose: () => location.replace("/"),
    });
  }

  useEffect(() => {
    update(api, token, setTotalInfo);

    const { search } = location;

    const [menu] = search.replace("?", "").split("&");

    setMenu(menu.split("=")[1] ? menu.split("=")[1] : "draw");
  }, []);

  const chargeCash = () => {
    if (amount) {
      axios
        .post(
          `${api}/cash/charge/bank`,
          {
            cash: amount,
            price: amount,
            item_name: `$ ${amount}`,
          },
          {
            headers: {
              "ngrok-skip-browser-warning": "69420",
              Authorization: "bearer " + token,
            },
          }
        )
        .then(({ data }) => {
          if (data.message) {
            Swal.fire({
              title: "Success",
              icon: "success",
              allowOutsideClick: false,
              didClose: () => location.reload(),
            });
          }
        })
        .catch(({ response }) => {
          const status = response.status;

          if (status === 400) {
            Swal.fire({
              title: "Error",
              text: "You have a charge request that hasn't been completed.",
              icon: "error",
              allowOutsideClick: false,
            });
            setOpen(false);
          }
        });
    } else {
      Swal.fire({
        title: "Error",
        text: "You cannot charge $0. Please select other option.",
        icon: "error",
        allowOutsideClick: false,
      });
      setOpen(false);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  return (
    <>
      <div className="sub mypage">
        <div className="user-info-area">
          <img src={UserIco} alt="User" className="user-ico" />
          <div className="user-info">
            <p className="name">
              {info.firstName} {info.lastName}
            </p>
            <div className="email-phone-wrap">
              <div className="item">
                <p className="tit">Email address</p>
                <p className="txt">{info.email}</p>
              </div>
              <div className="item">
                <p className="tit">Phone number</p>
                <p className="txt">
                  {info.phone.slice(0, 2)}-{info.phone.slice(2, 6)}-
                  {info.phone.slice(6, 11)}
                </p>
              </div>
            </div>
          </div>
          <div className="balance-winning-wrap">
            <div className="item">
              <h4 className="tit">Account Balance</h4>
              <p>$ {info.cash.toLocaleString("au")}</p>
              <div className="btn" onClick={() => setOpen(true)}>
                Add Funds
              </div>
            </div>
            <div className="item">
              <h4 className="tit">My Winnings</h4>
              <p>$ {info.prize}</p>
              <div className="btn" onClick={() => setOpenWithDraw(true)}>
                Withdraw
              </div>
            </div>
          </div>
        </div>

        <ul className="tab style01">
          {cate.map((it, idx) => (
            <li
              key={idx}
              className={menu === it.menu ? "current" : ""}
              onClick={() => (location.href = it.href)}
            >
              {it.title}
            </li>
          ))}
        </ul>
        {/* Tab - 페이지 이동 */}
        {changeView(menu)}
      </div>
      {/* Add Funds 모달창 */}
      {/* 결제(충전) -> 카드 선택, 무통장 입금 처리 방식 상의 */}
      <CustModal open={open} setOpen={setOpen} onClose={() => setOpen(false)}>
        <div className="add-fund">
          <div className="modal-tit-wrap">
            <h3 className="tit">Add Funds</h3>
            <span
              className="btn close material-symbols-rounded"
              onClick={() => setOpen(false)}
            >
              close
            </span>
          </div>
          <div className="modal-cont-wrap">
            <form>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Funds</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={amount}
                  label="Funds"
                  onChange={({ target }) => {
                    const value = target.value as number;
                    setAmount(value);
                  }}
                >
                  {new Array(10).fill(1).map((_, index) => (
                    <MenuItem
                      value={7 * (index + 1)}
                      key={`money_${index * 7}_A$`}
                    >
                      $ {7 * (index + 1)}.00
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </form>

            <div className="company-account-info">
              <h3>Account Informations</h3>
              <div className="info">
                <img src={BankImg} alt="Line bank" />
                <ul>
                  <li>
                    <p className="tit">Deposit Amount : </p>
                    <p className="txt">$ {amount.toLocaleString("au")}.00</p>
                  </li>
                  <li>
                    <p className="tit">Account Number : </p>
                    <p className="txt">1234 5678 9000</p>
                  </li>
                  <li>
                    <p className="tit">Account Holder : </p>
                    <p className="txt">The Lottery Cluster</p>
                  </li>
                </ul>
              </div>
              <div className="notice-area">
                <p>
                  After making a deposit via bank transfer, you can check your
                  transaction details on My Page.
                </p>
              </div>
            </div>
          </div>
          <div className="modal-bottom-wrap">
            <div className="btn-wrap">
              <div className="btn style01 sm" onClick={() => setOpen(false)}>
                Cancel
              </div>
              <div
                className="btn style01 sm white"
                style={{ padding: "12px 36px" }}
                onClick={() => chargeCash()}
              >
                Add
              </div>
            </div>
          </div>
        </div>
      </CustModal>

      {/* Withdraw 모달창(출금) */}
      <CustModal
        open={openWithdraw}
        setOpen={setOpenWithDraw}
        onClose={() => setOpenWithDraw(false)}
      >
        {/* 계좌 번호 작성 부분 -> 어떤 방식으로 설계하는지? (등록, 기억, 변경, 선택) 상의 */}
        <div className="withdraw">
          <div className="modal-tit-wrap">
            <h3 className="tit">Withdraw</h3>
            <span
              className="btn close material-symbols-rounded"
              onClick={() => setOpenWithDraw(false)}
            >
              close
            </span>
          </div>
          <div className="modal-cont-wrap">
            <div className="my-account-info">
              <p className="account-num">Account Number : 1234 5678 9010</p>

              <RadioGroup
                value={selectedValue}
                onChange={handleChange}
                name="radio-buttons-group"
              >
                <FormControlLabel
                  // DOM 에서 isChecked로 className chk 포함 항목 (선택항목) 찾기 isChecked
                  id="isChecked"
                  value="total"
                  control={<Radio />}
                  label={
                    <div className="amount">
                      <p className="tit">Total amount</p>
                      <p className="txt">$ {info.prize}</p>
                    </div>
                  }
                  className={selectedValue === "total" ? "item chk" : "item"}
                />

                <FormControlLabel
                  value="other"
                  control={<Radio />}
                  label={
                    <div className="amount">
                      <p className="tit">Other amount</p>
                      <TextField
                        disabled={selectedValue !== "other"}
                        className="other-textfield"
                        size="small"
                        fullWidth
                      />
                    </div>
                  }
                  className={selectedValue === "other" ? "item chk" : "item"}
                />
              </RadioGroup>
            </div>
          </div>
          <div className="modal-bottom-wrap">
            <div className="btn-wrap">
              <div
                className="btn style01 sm"
                onClick={() => setOpenWithDraw(false)}
              >
                Cancel
              </div>
              <div
                className="btn style01 sm white"
                // chk 포함 -> 선택 항목 값 찾기
                onClick={() =>
                  console.log(
                    document
                      .getElementById("isChecked")
                      ?.className.includes("chk")
                  )
                }
              >
                Withdraw
              </div>
            </div>
          </div>
        </div>
      </CustModal>
    </>
  );
};

export default Mypage;
