// tools
import { useEffect, useState } from "react";
import User from "../tools/userInfo";

// comp
import SynItem from "../components/syndicates/synItem";
import axios from "axios";

// type
export interface Nums {
  idx: number;
  num: string;
}
export interface SyndicateInfo {
  idx: number;
  name: string;
  systemCode: string | null;
  auDrawTime: string;
  description: string;
  drawnum: number;
  games: number;
  jackpot: number;
  multiplier: string;
  price: string;
  productCode: string;
  ticketIssued: number;
  ticketSold: number;
  nums: Nums[];
}

const Syndicates = () => {
  const { api, token, setToken, setTotalInfo } = User();
  const [data, setData] = useState<SyndicateInfo[]>([]);

  const getSynData = () => {
    axios
      .get(`${api}/product/syndicate`, {
        headers: {
          "ngrok-skip-browser-warning": "69420",
        },
      })
      .then((res) => setData(res.data.data))
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    getSynData();
  }, []);

  return (
    <div className="sub syndicate">
      <div className="sub-top-area">
        <h3 className="tit">Syndicates</h3>
        <p className="txt">Play with a group and get more chances to win</p>
      </div>
      <div className="syndicate-wrap">
        {data.map((it) => (
          <SynItem key={it.idx} data={it} getSynData={getSynData} />
        ))}
      </div>
    </div>
  );
};

export default Syndicates;
