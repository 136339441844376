// tools
import { useState, useEffect } from "react";
import { useLotto } from "../../App";

// constants
import { SideMenu } from "../../constants/sideMenu";

const SideBar = () => {
  const [activeTab, setActiveTab] = useState<string | null>(null);
  const { navOpen, setNavOpen } = useLotto();

  useEffect(() => {
    const currentPath = window.location.pathname + window.location.search;
    setActiveTab(currentPath);
  }, []);

  const handleMenuClick = (href: string, isMagazine: boolean = false) => {
    // Magazine 예외처리
    if (isMagazine) {
      setActiveTab("/magazine");
      window.location.href = "/magazine";
    } else {
      setActiveTab(href);
      window.location.href = href;
    }
  };

  const isSubItemActive = (subItems: { href: string }[]) => {
    return subItems.some((subItem) => activeTab?.startsWith(subItem.href));
  };

  return (
    <div className={`side-nav-wrap ${navOpen ? "active" : ""}`}>
      <div className={`side-nav ${navOpen ? "active" : ""}`}>
        {SideMenu.map((menuItem) => {
          const hasActiveSubItem =
            menuItem.subItems && isSubItemActive(menuItem.subItems);
          const isActive =
            activeTab?.startsWith(menuItem.href) || hasActiveSubItem;

          // Magazine 예외처리
          const isMagazineActive =
            menuItem.title === "Magazine" && activeTab === "/magazine";

          return (
            <div key={menuItem.href} className="group">
              <div
                className={`${menuItem.tab} ${
                  isActive || isMagazineActive ? "current" : ""
                }`}
                onClick={() =>
                  handleMenuClick(menuItem.href, menuItem.title === "Magazine")
                }
              >
                <p>
                  <img src={menuItem.img} alt={menuItem.title} />
                  {menuItem.title}
                </p>
              </div>
              {menuItem.subItems &&
                menuItem.subItems.map((subItem) => (
                  <div
                    className={
                      activeTab?.startsWith(subItem.href)
                        ? "current sub-item"
                        : "sub-item"
                    }
                    key={subItem.href}
                    onClick={() => handleMenuClick(subItem.href)}
                  >
                    {subItem.title}
                  </div>
                ))}
            </div>
          );
        })}
      </div>
      <div className="dim" onClick={() => setNavOpen((prev) => !prev)}></div>
    </div>
  );
};

export default SideBar;
