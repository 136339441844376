// tool
import React, { useState, useEffect, useRef, Dispatch } from "react";
import axios from "axios";
import User from "../../tools/userInfo";

// constants
import Division from "../../constants/division";

// components
import Slider from "react-slick";
import NoData from "../common/noData";

interface Props {
  type: string;
}

interface Playdate {
  drawnum: number;
  playdate: string;
}

interface Data {
  division: DivisionProps[];
  number: string[];
}

interface DivisionProps {
  ballset: BallSet;
  prize: string;
  winner: string;
}

interface BallSet {
  white: number;
  special: boolean;
}

const LastWinningResult = ({ type }: Props) => {
  const { api } = User();
  const ref = useRef<Slider | null>(null);
  const selectRef = useRef<HTMLUListElement | null>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const [toggleDateLists, setToggleDateLists] = useState(false);
  const [selected, setSelected] = useState<Playdate>({
    drawnum: 0,
    playdate: "",
  });
  const [list, setList] = useState<Playdate[]>([]);
  const [data, setData] = useState<Data>({
    division: [],
    number: [],
  });

  const [show, setShow] = useState<Playdate[]>([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (type) {
      const ball = type === "mega" ? "mm" : "pb";

      axios
        .get(`${api}/playdate/${ball}`, {
          headers: {
            "ngrok-skip-browser-warning": "69420",
          },
        })
        .then(({ data }) => {
          const Data: Playdate[] = data.data;
          setList(Data);
          setSelected(Data[0]);
          setShow(Data.slice(0, 20));
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [type]);

  useEffect(() => {
    if (selected.drawnum !== 0) {
      specificAxios();
    }
  }, [selected.drawnum]);

  const specificAxios = () => {
    if (selected.drawnum) {
      const ball = type === "mega" ? "mm" : "pb";

      axios
        .get(`${api}/prize/${ball}/${selected.drawnum}`, {
          headers: {
            "ngrok-skip-browser-warning": "69420",
          },
        })
        .then(({ data }) => {
          const Data = data.data;
          const raw: DivisionProps[] = [];
          const prizeArray = Data.prize.split(",");
          const winnerArray = Data.prizeCnt.split(",");

          for (let i = 0; i < 9; i++) {
            const dataset = {
              ballset: Division[i],
              prize: prizeArray[i],
              winner: winnerArray[i],
            };

            raw.push({ ...dataset });
          }

          setData({
            division: raw,
            number: data.data.winningNumber.split(","),
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const updateShow = (selected: Playdate, initial = false) => {
    const currentIndex = list.findIndex(
      (item) => item.drawnum === selected.drawnum
    );
    let start, end;

    if (initial) {
      start = 0;
      end = 20;
    } else {
      start = Math.max(currentIndex - 5, 0);
      end = Math.min(currentIndex + 6, list.length);
      if (currentIndex === list.length - 1) {
        // 마지막 날짜
        start = Math.max(list.length - 11, 0);
        end = list.length;
      }
    }
    setShow(list.slice(start, end));
  };

  const changeSelected = (select: Playdate) => {
    setSelected(select);
    setToggleDateLists(false);
    updateShow(select);

    if (selectRef.current) {
      selectRef.current.scrollTop = 0;
    }

    const reverse = [...list].reverse();
    const current = reverse.findIndex(
      (target) => target.drawnum === select.drawnum
    );
    ref.current?.slickGoTo(current, true);
  };

  const loadMore = () => {
    const newPage = page + 1;
    const newShow = list.slice(0, newPage * 50);
    setShow(newShow);
    setPage(newPage);
  };

  const toggleDropdown = () => {
    setToggleDateLists((prev) => !prev);
    if (!toggleDateLists && selectRef.current) {
      const currentIndex = list.findIndex(
        (item) => item.drawnum === selected.drawnum
      );
      const itemHeight = 40; // 각 li높이 (px)
      selectRef.current.scrollTop = currentIndex * itemHeight;
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setToggleDateLists(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const PrevArrow = (props: any) => {
    const clickFunc = () => {
      const { currentSlide } = props;

      if (currentSlide !== 0) {
        const copy = [...list];
        ref.current?.slickPrev();
        const current = copy.reverse()[currentSlide - 1];
        setSelected(current);
        updateShow(current);
      }
      setToggleDateLists(false);
    };

    return (
      <div className="btn arr prev" onClick={clickFunc}>
        <span className="material-symbols-outlined">chevron_left</span>
      </div>
    );
  };

  const NextArrow = (props: any) => {
    const clickFunc = () => {
      const { currentSlide, slideCount } = props;

      if (currentSlide + 1 !== slideCount) {
        const copy = [...list];
        ref.current?.slickNext();
        const current = copy.reverse()[currentSlide + 1];
        setSelected(current);
        updateShow(current);
      }
      setToggleDateLists(false);
    };

    return (
      <div className="btn arr next" onClick={clickFunc}>
        <span className="material-symbols-outlined">chevron_right</span>
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: list.length - 1,
    draggable: false,
    pauseOnHover: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    className: "box-slider",
  };

  const [isHovered, setIsHovered] = useState(false);

  // close the dateList Popup
  const closeToggle = () => {
    if (toggleDateLists === true) {
      setToggleDateLists(false);
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
    setTimeout(() => {
      loadMore();
      setIsHovered(false);
    }, 1000);
  };

  return (
    <div
      className="box-slider-wrap"
      ref={dropdownRef}
      onClick={() => closeToggle()}
    >
      <div className="box-tit-wrap">
        {list.length > 0 && (
          <Slider {...settings} ref={ref}>
            {[...list].reverse().map((item) => (
              <div className="item" key={item.playdate}>
                <p className="tit" onClick={toggleDropdown}>
                  {new Date(selected.playdate).toDateString()}
                  <span className="material-symbols-rounded">
                    keyboard_arrow_down
                  </span>
                </p>

                {toggleDateLists && (
                  <ul className="date-list" ref={selectRef} id="infi-scroll">
                    {show.map((data) => (
                      <li
                        key={data.drawnum}
                        onClick={() => changeSelected(data)}
                        className={
                          data.drawnum === selected.drawnum
                            ? "seleted-date"
                            : ""
                        }
                      >
                        {new Date(data.playdate).toDateString()}
                      </li>
                    ))}
                    {show.length < list.length &&
                      selected.drawnum !== list[list.length - 1].drawnum && (
                        <div
                          onMouseEnter={handleMouseEnter}
                          className={`btn style01 sm show-more  ${
                            isHovered === true ? "loading" : ""
                          }`}
                        >
                          {isHovered ? (
                            <span className="loader" />
                          ) : (
                            "Show more"
                          )}
                        </div>
                      )}
                  </ul>
                )}
              </div>
            ))}
          </Slider>
        )}
      </div>

      <div className="ball-wrap result">
        {data.number.map((num, index) => (
          <div
            className={
              index === data.number.length - 1 ? `ball ${type}` : "ball"
            }
            key={`${selected.drawnum}_${num}_${index}`}
          >
            {num}
          </div>
        ))}
      </div>

      {/* Division */}
      {data.division.filter((div) => Boolean(div.winner)).length > 0 ? (
        <div className="table-wrap">
          <table>
            <tr>
              <th>Division</th>
              <th>Winning Combination</th>
              <th>Prize</th>
              <th>Winners</th>
            </tr>

            {data.division.map((rank, index) => (
              <tr key={`${selected.drawnum}_${index}`}>
                <td>Division &nbsp;{index + 1}</td>
                <td>
                  <div className="ball-wrap">
                    {new Array(rank.ballset.white).fill(1).map((_, i) => (
                      <div
                        className="ball"
                        key={`${selected.drawnum}_${index}_${i}`}
                      />
                    ))}

                    {rank.ballset.special ? (
                      <div className={`ball ${type}`} />
                    ) : (
                      <></>
                    )}
                  </div>
                </td>
                <td>$ {Number(rank.prize).toLocaleString("au")}</td>
                <td>{Number(rank.winner).toLocaleString("au")}</td>
              </tr>
            ))}
          </table>
        </div>
      ) : (
        <NoData />
      )}
    </div>
  );
};

export default LastWinningResult;
