import { useState, useEffect } from "react";
import axios from "axios";
import User from "../../tools/userInfo";

interface BarChart {
  type: string;
  menu: string;
  month: string;
  tab: string;
}

interface Ball {
  number: number;
  count: number;
}

interface Data {
  total: number;
  white: Ball[];
  special: Ball[];
}

const Bar = ({ type, menu, month, tab }: BarChart) => {
  const { api } = User();
  const BallType = type === "power" ? "Power Ball" : "Mega Ball";
  const [list, setList] = useState<Data>({
    total: 0,
    white: [],
    special: [],
  });
  const [compareList, setCompareList] = useState<Data>({
    total: 0,
    white: [],
    special: [],
  });

  /** 날짜 선택 */
  function getAdjustedDateRange(option: string): string[] {
    const today = new Date();
    let adjustedDate = new Date(today);

    switch (option) {
      case "1":
        adjustedDate.setMonth(today.getMonth() - 1);
        break;
      case "2":
        adjustedDate.setMonth(today.getMonth() - 2);
        break;
      case "3":
        adjustedDate.setMonth(today.getMonth() - 3);
        break;
      case "4":
        adjustedDate.setMonth(today.getMonth() - 4);
        break;
      case "5":
        adjustedDate.setMonth(today.getMonth() - 5);
        break;
      case "12":
        adjustedDate.setFullYear(today.getFullYear() - 1);
        break;
      case "36":
        adjustedDate.setFullYear(today.getFullYear() - 3);
        break;
      case "60":
        adjustedDate.setFullYear(today.getFullYear() - 5);
        break;
      default:
        return [];
    }

    const formatDate = (date: Date) => date.toISOString().split("T")[0];
    return [formatDate(today), formatDate(adjustedDate)];
  }

  const selectedDates = getAdjustedDateRange(month);

  const selectCompare = (target: string) => {
    if (target === "frequency") {
      dataAxios();
    }
  };

  const dataAxios = () => {
    const nowDate = selectedDates[0];
    const curDate = selectedDates[1];

    axios
      .get(
        `${api}/number/${tab}?type=${
          type === "power" ? "pb" : "mm"
        }&sDate=${curDate}&eDate=${nowDate}`,
        {
          headers: {
            "ngrok-skip-browser-warning": "69420",
          },
        }
      )
      .then(({ data }) => {
        const Data = data.data;
        if (menu === "frequency") {
          setList({
            total: Data.total_drawnum_all,
            white: Data.white_all,
            special: Data.power_all,
          });

          setCompareList({
            total: Data.total_drawnum_filtered,
            white: Data.white_filtered,
            special: Data.power_filtered,
          });
        } else {
          const white = [];
          const special = [];

          for (let i = 0; i < Data.white_all.length; i++) {
            white.push({
              number: Data.white_all[i].number,
              count: Data.white_all[i].timesince,
            });
          }

          for (let i = 0; i < Data.power_all.length; i++) {
            special.push({
              number: Data.power_all[i].number,
              count: Data.power_all[i].timesince,
            });
          }

          setList({
            total: Data.total_drawnum_all,
            white,
            special,
          });
        }
      });
  };

  useEffect(() => {
    dataAxios();
  }, []);

  useEffect(() => {
    selectCompare(menu);
  }, [month]);

  return (
    <div className="bars">
      <div className="item">
        <div className="tit-wrap">
          <h4>White Ball</h4>
          {menu === "frequency" && (
            <div className="label">
              <div className="bar"></div>
              <span>Total</span>
              <div className="bar compare"></div>
              <span className="compare">{month} month</span>
            </div>
          )}
        </div>

        <table>
          <tr>
            <th>No.</th>
            <th>
              {menu === "frequency" ? "Frequency Graph" : "Appearence Graph"}
            </th>
            <th>{menu === "frequency" ? "Frequency" : "Last Appearence"}</th>
          </tr>

          {/* white 데이터를 안전하게 map으로 렌더링 */}
          {list.white && list.white.length > 0 ? (
            list.white.map((item) => {
              const max = list.white.reduce((prev, value) =>
                prev.count >= value.count ? prev : value
              );

              const maxCompare = compareList.white.reduce(
                (prev, value) => (prev.count >= value.count ? prev : value),
                { count: 0 }
              );

              const compareItem = compareList.white.find(
                (compare) => compare.number === item.number
              );

              return (
                <tr key={item.number}>
                  <td>{item.number}</td>
                  <td>
                    <div className="td-wrap">
                      <div
                        className="bar"
                        style={{
                          width: `${(item.count / max.count) * 100}%`,
                        }}
                      />
                      <p>{((item.count / list.total) * 100).toFixed(1)}%</p>
                    </div>
                    {compareItem && menu === "frequency" && (
                      <div className="td-wrap compare">
                        <div
                          className="bar"
                          style={{
                            width: `${
                              (compareItem.count / maxCompare.count) * 100
                            }%`,
                          }}
                        />
                        <p className="compare">
                          {compareItem
                            ? `${(
                                (compareItem.count / compareList.total) *
                                100
                              ).toFixed(1)}%`
                            : "0%"}
                        </p>
                      </div>
                    )}
                  </td>

                  <td>
                    <div className="td-wrap">
                      {menu === "frequency"
                        ? `${item.count} times`
                        : `${
                            item.count
                              ? `${item.count + 1} draws ago`
                              : "last draw"
                          }`}
                    </div>
                    {menu === "frequency" && (
                      <div className="td-wrap compare">
                        {compareItem ? `${compareItem.count} times` : "0 times"}
                      </div>
                    )}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={3}>No data available</td>
            </tr>
          )}
        </table>
      </div>

      <div className="item">
        <div className="tit-wrap">
          <h4 className={type === "power" ? "power" : "mega"}>{BallType}</h4>
          {menu === "frequency" && (
            <div className="label">
              <div
                className={type === "power" ? "power bar" : "mega bar"}
              ></div>
              <span className={type === "power" ? "power" : "mega"}>Total</span>
              <div className="bar compare"></div>
              <span className="compare">{month} month</span>
            </div>
          )}
        </div>

        <table>
          <tr>
            <th>Number</th>
            <th>
              {menu === "frequency" ? "Frequency Graph" : "Appearence Graph"}
            </th>
            <th>{menu === "frequency" ? "Frequency" : "Last Appearence"}</th>
          </tr>

          {/* special 데이터를 안전하게 map으로 렌더링 */}
          {list.special && list.special.length > 0 ? (
            list.special.map((item) => {
              const max = list.special.reduce((prev, value) =>
                prev.count >= value.count ? prev : value
              );

              const maxCompare = compareList.special.reduce(
                (prev, value) => (prev.count >= value.count ? prev : value),
                { count: 0 }
              );

              const compareItem = compareList.special.find(
                (compare) => compare.number === item.number
              );

              return (
                <tr key={item.number}>
                  <td>
                    <div>
                      <div className={`ball ${type}`}>{item.number}</div>
                    </div>
                  </td>
                  <td>
                    <div className="td-wrap">
                      <div
                        className={type === "power" ? "power bar" : "mega bar"}
                        style={{
                          width: `${(item.count / max.count) * 100}%`,
                        }}
                      />
                      <p>{((item.count / list.total) * 100).toFixed(1)}%</p>
                    </div>
                    {compareItem && menu === "frequency" && (
                      <div className="td-wrap compare">
                        <div
                          className={
                            type === "power" ? "power bar" : "mega bar"
                          }
                          style={{
                            width: `${
                              (compareItem.count / maxCompare.count) * 100
                            }%`,
                          }}
                        />
                        <p>
                          {compareItem
                            ? `${(
                                (compareItem.count / compareList.total) *
                                100
                              ).toFixed(1)}%`
                            : "0%"}
                        </p>
                      </div>
                    )}
                  </td>
                  <td>
                    <div className="td-wrap">
                      {menu === "frequency"
                        ? `${item.count} times`
                        : `${
                            item.count
                              ? `${item.count + 1} draws ago`
                              : "last draw"
                          }`}
                    </div>
                    {menu === "frequency" && (
                      <div className="td-wrap compare">
                        {compareItem ? `${compareItem.count} times` : "0 times"}
                      </div>
                    )}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={3}>No data available</td>
            </tr>
          )}
        </table>
      </div>
    </div>
  );
};

export default Bar;
