// tools
import React, { useEffect } from "react";
import { useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { CartItems, Nums } from "../../pages/cart";
import axios from "axios";
import User from "../../tools/userInfo";

// img
import MegaBallLogo from "../../img/common/mega-ball-logo.png";
import PowerBallLogo from "../../img/common/power-ball-logo.png";
import FavIco from "../../img/sub/favorite-ico.svg";
import FavIcoChk from "../../img/sub/favorite-ico-fill.svg";

// ui
import { Skeleton } from "@mui/material";

// comp
import ShowNums from "./showNums";

// type
interface CartItemProps {
  item: CartItems;
  setItems: React.Dispatch<React.SetStateAction<CartItems[]>>;
  delCartType: (target: number | string) => void;
  getCart: () => void;
  loading: boolean;
}

const CartItem = ({
  item,
  delCartType,
  setItems,
  getCart,
  loading,
}: CartItemProps) => {
  // tools
  const { api, token } = User();

  // stat
  const [ballEditOpen, setBallEditOpen] = useState<boolean>(false);
  const [isPicked, setIsPicked] = useState<boolean>(false);

  const [cartItemOpen, setCartItemOpen] = useState<boolean>(false);
  const [stopMulti, setStopMulti] = useState<boolean>(false);

  // local
  const storedData = JSON.parse(localStorage.getItem("noLgCart") || "[]");

  console.log(item);

  // func
  const amountMaker = (amount: number) => {
    const inmil = Number((amount / 1000000).toFixed(1));
    let final = 0;

    if (inmil > 1000) {
      const inbil = inmil / 1000;
      final = Math.floor(inbil * 100) / 100;
    } else if (inmil > 0) {
      final = inmil;
    }
    return final;
  };

  const amountCur = (option: string, count: number) => {
    if (option === "Y") {
      return count * 10.45;
    } else {
      return count * 7;
    }
  };

  const isMode = (code: string) => {
    if (code === "MM") {
      return MegaBallLogo;
    } else {
      return PowerBallLogo;
    }
  };

  const isTitle = (code: string) => {
    if (code === "MM") {
      return "Mega Millions";
    } else {
      return "Powerball";
    }
  };

  const isBall = (type: string) => {
    if (type === "MM") {
      return "mega";
    } else {
      return "power";
    }
  };

  const editCartType = (target: number | string, multi: string) => {
    if (typeof target === "string") {
      handleMulti(parseInt(target, 10), multi);
    } else {
      handleMulti(target, multi);
    }
  };

  // api
  const delTicket = (target: number, itemKey: number) => {
    if (token.length === 0) {
      const findData = storedData.find((it: any) => it.idx === target);

      if (findData) {
        const updatedNums = findData.nums.filter(
          (it: any) => it.idx !== itemKey
        );

        const checkQ = findData.quantity - 1;

        const updatedData = storedData.map((storedItem: any) => {
          if (storedItem.idx === target) {
            return {
              ...storedItem,
              nums: updatedNums,
              quantity: storedItem.quantity - 1,
              amount: amountCur(storedItem.multiplier, checkQ),
            };
          }
          return storedItem;
        });

        localStorage.setItem("noLgCart", JSON.stringify(updatedData));
        setItems(updatedData);
      }
    } else {
      axios
        .delete(`${api}/cart/${target}/nums/${itemKey}`, {
          headers: {
            "ngrok-skip-browser-warning": "69420",
            Authorization: "bearer " + token,
          },
        })
        .then((res) => getCart())
        .catch((error) => console.error(error));
    }
  };

  const handleMulti = (
    target: number | undefined,
    multi: string | undefined
  ) => {
    setStopMulti(true);
    const changeMulti = multi === "N" ? "Y" : "N";

    setTimeout(() => {
      setStopMulti(false);
    }, 2000);

    if (token.length === 0) {
      const findData = storedData.find((it: any) => it.idx === target);

      const changeAmount = amountCur(changeMulti, findData.quantity);

      const updatedData = storedData.map((storedItem: CartItems) => {
        if (storedItem.idx === target) {
          return {
            ...storedItem,
            multiplier: changeMulti,
            amount: changeAmount,
          };
        }
        return storedItem; // 나머지 유지
      });

      localStorage.setItem("noLgCart", JSON.stringify(updatedData));

      setItems(updatedData);
    } else {
      setItems((prev) => {
        if (!Array.isArray(prev)) return prev;
        return prev.map((item) =>
          item.idx === target ? { ...item, multiplier: changeMulti } : item
        );
      });

      const data = {
        multiplier: changeMulti,
      };

      axios
        .put(
          `${api}/cart/${target}`,
          data,

          {
            headers: {
              "ngrok-skip-browser-warning": "69420",
              Authorization: "bearer " + token,
            },
          }
        )
        .then((res) => {
          getCart();
          console.log(res.data.data);
        })
        .catch((error) => console.log(error));
    }
  };

  useEffect(() => {
    if (item.ticketQty === 0) {
      console.log("check");
      delCartType(item.idx);
    }
  }, [item]);

  // card type func
  const isCardType = (card: CartItems) => {
    if (card.methodCode === "SYN" || card.systemCode === null) {
      const spBall = card.productCode;
      return (
        <div className="item">
          <div
            className={`top-area ${cartItemOpen && "open"}`}
            onClick={() => {
              setCartItemOpen((prev) => !prev);
            }}
          >
            <div className="game">
              <img src={isMode(card.productCode)} />
              <div className="name-tag-wrap">
                <p className="name">{card.productName}</p>
                {card.methodCode === "SYN" && (
                  <p className="tag syndicate">Syndicate</p>
                )}
                {card.multiplier === "Y" && (
                  <p className="tag multiplier">Multiplier</p>
                )}
              </div>
            </div>
            <div className="num-price-wrap">
              <p className="num">{card.ticketQty} Games</p>
              <p className="price">$ {card.totalAmount}</p>
            </div>
            <div className="btn-wrap">
              <div
                className="btn style01 sm delete"
                onClick={(e) => {
                  e.stopPropagation();
                  delCartType(item.idx);
                }}
              >
                <span className="material-symbols-rounded">delete</span>
              </div>
              <span className="material-symbols-rounded arr">
                keyboard_arrow_down
              </span>
            </div>
          </div>
          {cartItemOpen && (
            <div className="bottom-area">
              <div className="game">
                <p className="prize">
                  $ {amountMaker(Number(card.jackpot))} million
                </p>
                {Array.isArray(card.playdate) ? (
                  card.playdate.map((it, idx) => (
                    <p key={idx} className="date">
                      {it}
                    </p>
                  ))
                ) : (
                  <p className="date">{card.playdate}</p>
                )}
              </div>
              <div className="show-number">
                <div className="number-wrap">
                  {card.nums.map((it, idx) => (
                    <div key={idx} className="item">
                      <p>#{idx + 1}</p>
                      <div className="ball-wrap">
                        {it.num.split(",").map((num, idx) => (
                          <div
                            key={idx}
                            className={`ball ${
                              idx === it.num.split(",").length - 1
                                ? isBall(card.productCode)
                                : ""
                            }`}
                          >
                            {num}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      );
    } else if (card?.methodCode === "STD") {
      const spBall = card.productCode;
      return (
        <div className="item">
          <div
            className={`top-area ${cartItemOpen && "open"}`}
            onClick={() => {
              setCartItemOpen((prev) => !prev);
            }}
          >
            <div className="game">
              <img src={isMode(card.productCode)} />
              <div className="name-tag-wrap">
                <p className="name">{card.productName}</p>
                {card.multiplier === "Y" && (
                  <p className="tag multiplier">Multiplier</p>
                )}
              </div>
            </div>
            <div className="num-price-wrap">
              <p className="num">{card.ticketQty} Games</p>
              <p className="price">$ {card.totalAmount}</p>
            </div>
            <div className="btn-wrap">
              <div
                className="btn style01 sm delete"
                onClick={(e) => {
                  e.stopPropagation();
                  delCartType(item.idx);
                }}
              >
                <span className="material-symbols-rounded">delete</span>
              </div>
              <span className="material-symbols-rounded arr">
                keyboard_arrow_down
              </span>
            </div>
          </div>

          {cartItemOpen && (
            <div className="bottom-area">
              <div className="game">
                <p className="prize">
                  $ {amountMaker(Number(card.jackpot))} million
                </p>
                {/* {card.playdate.map((it, idx) => (
                  <p key={idx} className="date">
                    {it}
                  </p>
                ))} */}
                {Array.isArray(card.playdate) ? (
                  card.playdate.map((it, idx) => (
                    <p key={idx} className="date">
                      {it}
                    </p>
                  ))
                ) : (
                  <p className="date">{card.playdate}</p>
                )}
              </div>

              <div className="ticket-wrap">
                {card.nums.map((it: Nums, idx: number) => (
                  <div className="item" key={idx}>
                    <div className="ticket-tit-wrap">
                      <p className="">Game #{idx + 1}</p>
                      <div className="btn-wrap">
                        {/* <span className="btn material-symbols-rounded">bolt</span> */}
                        {/* <span className="btn material-symbols-rounded">mop</span> */}
                        {/* <img
                      className="btn"
                      src={fav ? FavIco : FavIcoChk}
                      onClick={() => {
                        setFav(!fav);
                      }}
                    /> */}
                        <span
                          className="btn material-symbols-rounded"
                          onClick={() =>
                            delTicket(card.idx, card.nums[idx].idx)
                          }
                        >
                          delete
                        </span>
                      </div>
                    </div>
                    <div className="picked-ball-wrap">
                      <div className="ball-wrap">
                        {it.num
                          .split(",")
                          .map(
                            (number: string, index: number, arr: string[]) => (
                              <div
                                key={index}
                                className={`ball ${
                                  index === arr.length - 1 ? isBall(spBall) : ""
                                }`}
                              >
                                {number}
                              </div>
                            )
                          )}

                        {/* <span
    className={`material-symbols-rounded ${ballEditOpen ? "open" : ""}`}
  >
    keyboard_arrow_down
  </span> */}
                      </div>
                      {ballEditOpen && (
                        <>
                          <div className="edit-ball-wrap">
                            <div className="ball-count">
                              <div className="tit-count-wrap">
                                <p className="tit">Ball</p>
                                <p className="count">
                                  <span>1</span>of 5
                                </p>
                              </div>
                            </div>

                            <div className="ball-wrap">
                              {[
                                1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,
                                15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
                                27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38,
                                39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50,
                                51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62,
                                63, 64, 65, 66, 67, 68, 69, 70,
                              ].map((a, i) => {
                                return (
                                  <div
                                    className={`ball ${
                                      isPicked ? "picked" : ""
                                    }`}
                                    key={i}
                                    onClick={() => {
                                      setIsPicked(!isPicked);
                                    }}
                                  >
                                    {a}
                                  </div>
                                );
                              })}
                            </div>

                            <div
                              className="tit-count-wrap"
                              style={{ marginTop: "12px" }}
                            >
                              <p className="mega">Mega ball</p>
                              <p className="count">
                                <span>1</span>of 1
                              </p>
                            </div>

                            <div className="ball-wrap mega">
                              {[
                                1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,
                                15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
                              ].map((a, i) => {
                                return (
                                  <div
                                    className={`ball ${
                                      isPicked ? "picked" : ""
                                    }`}
                                    key={i}
                                    onClick={() => {
                                      setIsPicked(!isPicked);
                                    }}
                                  >
                                    {a}
                                  </div>
                                );
                              })}
                            </div>
                          </div>

                          <div className="btn style01 md">Confirm</div>
                        </>
                      )}
                    </div>
                  </div>
                ))}

                <div className="item multiplier">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={card.multiplier === "Y"}
                        disabled={stopMulti === true}
                        onClick={() => editCartType(card.idx, card.multiplier)}
                      />
                    }
                    label="Add multiplier $3.45 per game"
                    labelPlacement="start"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      );
    } else if (card?.systemCode.includes("ST")) {
      const systemTag = card.systemCode.substring(2);
      const sysCode = card.systemCode;
      return (
        <div className="item">
          <div
            className={`top-area ${cartItemOpen && "open"}`}
            onClick={() => setCartItemOpen((prev) => !prev)}
          >
            <div className="game">
              <img src={isMode(card.productCode)} alt={card.productCode} />
              <div className="name-tag-wrap">
                <p className="name">{card.productName}</p>
                <p className="tag system">System {systemTag}</p>
                {card.multiplier === "Y" && (
                  <p className="tag multiplier">Multiplier</p>
                )}
              </div>
            </div>
            <div className="num-price-wrap">
              <p className="num">{card.ticketQty} Games</p>
              <p className="price">$ {card.totalAmount}</p>
            </div>
            <div className="btn-wrap">
              {/* <div
                className="btn style01 sm"
                onClick={() => {
                  setCartItemOpen(!cartItemOpen);
                }}
              >
                Edit
              </div> */}
              <div
                className="btn style01 sm delete"
                onClick={(e) => {
                  e.stopPropagation();
                  delCartType(item.idx);
                }}
              >
                <span className="material-symbols-rounded">delete</span>
              </div>
              <span className="material-symbols-rounded arr">
                keyboard_arrow_down
              </span>
            </div>
          </div>

          {cartItemOpen && (
            <div className="bottom-area">
              <div className="game">
                <p className="prize">
                  $ {amountMaker(Number(card.jackpot))} million
                </p>
                {Array.isArray(card.playdate) ? (
                  card.playdate.map((it, idx) => (
                    <p key={idx} className="date">
                      {it}
                    </p>
                  ))
                ) : (
                  <p className="date">{card.playdate}</p>
                )}
              </div>

              <div className="ticket-wrap">
                {card.nums.map((it, idx) => (
                  <div className="item" key={idx}>
                    <div className="ticket-tit-wrap">
                      <p>Game #{idx + 1}</p>
                      <div className="btn-wrap">
                        <span
                          className="btn material-symbols-rounded"
                          onClick={() =>
                            delTicket(card.idx, card.nums[idx].idx)
                          }
                        >
                          delete
                        </span>
                      </div>
                    </div>

                    <div className="picked-ball-wrap">
                      <div className="ball-wrap">
                        {it.num.split(",").map((it, idx, arr: string[]) => (
                          <div
                            key={idx}
                            className={`ball ${
                              idx === arr.length - 1
                                ? isBall(card.productCode)
                                : ""
                            }`}
                          >
                            {it}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="item multiplier">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={card.multiplier === "Y"}
                            disabled={stopMulti === true}
                            onClick={() =>
                              editCartType(card.idx, card.multiplier)
                            }
                          />
                        }
                        label="Add multiplier $3.45 per game"
                        labelPlacement="start"
                      />
                    </div>

                    {/* ---- show nums ---- */}
                    <ShowNums
                      nums={it.num}
                      code={card.productCode}
                      sysCode={sysCode}
                    />
                    {/* ---- show nums ---- */}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      );
    } else if (card.systemCode === "PIC") {
      const sysCode = card.systemCode;
      return (
        <div className="item">
          <div
            className={`top-area ${cartItemOpen && "open"}`}
            onClick={() => {
              setCartItemOpen((prev) => !prev);
            }}
          >
            <div className="game">
              <img src={isMode(card.productCode)} alt={card.productCode} />
              <div className="name-tag-wrap">
                <p className="name">{card.productName}</p>
                <p className="tag pick" style={{ backgroundColor: "purple" }}>
                  Pick 1 less
                </p>
                {card.multiplier === "Y" && (
                  <p className="tag multiplier">Multiplier</p>
                )}
              </div>
            </div>
            <div className="num-price-wrap">
              <p className="num">{card.ticketQty} Games</p>
              <p className="price">$ {card.totalAmount}</p>
            </div>
            <div className="btn-wrap">
              {/* <div
                className="btn style01 sm"
                onClick={() => {
                  setCartItemOpen(!cartItemOpen);
                }}
              >
                Edit
              </div> */}
              <div
                className="btn style01 sm delete"
                onClick={(e) => {
                  e.stopPropagation();
                  delCartType(item.idx);
                }}
              >
                <span className="material-symbols-rounded">delete</span>
              </div>
              <span className="material-symbols-rounded arr">
                keyboard_arrow_down
              </span>
            </div>
          </div>

          {cartItemOpen && (
            <div className="bottom-area">
              <div className="game">
                <p className="prize">
                  $ {amountMaker(Number(card.jackpot))} million
                </p>
                {Array.isArray(card.playdate) ? (
                  card.playdate.map((it, idx) => (
                    <p key={idx} className="date">
                      {it}
                    </p>
                  ))
                ) : (
                  <p className="date">{card.playdate}</p>
                )}
              </div>

              <div className="ticket-wrap">
                {card.nums.map((it: Nums, idx: number) => (
                  <div className="item" key={idx}>
                    <div className="ticket-tit-wrap">
                      <p>Game #{idx + 1}</p>
                      <div className="btn-wrap">
                        <span
                          className="btn material-symbols-rounded"
                          onClick={() =>
                            delTicket(card.idx, card.nums[idx].idx)
                          }
                        >
                          delete
                        </span>
                      </div>
                    </div>
                    <div className="picked-ball-wrap">
                      <div className="ball-wrap">
                        {it.num.split(",").map((it, idx, arr: string[]) => (
                          <div
                            key={idx}
                            className={`ball ${
                              idx === arr.length - 1
                                ? isBall(card.productCode)
                                : ""
                            }`}
                          >
                            {it}
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="item multiplier">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={card.multiplier === "Y"}
                            disabled={stopMulti === true}
                            onClick={() =>
                              editCartType(card.idx, card.multiplier)
                            }
                          />
                        }
                        label="Add multiplier $3.45 per game"
                        labelPlacement="start"
                      />
                    </div>

                    {/* ---- show nums ---- */}
                    <ShowNums
                      nums={it.num}
                      code={card.productCode}
                      sysCode={sysCode}
                    />
                    {/* ---- show nums ---- */}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      );
    } else if (card.systemCode === "GUA") {
      const sysCode = card.systemCode;
      return (
        <div className="item">
          <div
            className={`top-area ${cartItemOpen && "open"}`}
            onClick={() => {
              setCartItemOpen((prev) => !prev);
            }}
          >
            <div className="game">
              <img src={isMode(card.productCode)} alt={card.productCode} />
              <div className="name-tag-wrap">
                <p className="name">{card.productName}</p>
                <p className="tag guaranteed">Guaranteed</p>
                {card.multiplier === "Y" && (
                  <p className="tag multiplier">Multiplier</p>
                )}
              </div>
            </div>
            <div className="num-price-wrap">
              <p className="num">{card.ticketQty} Games</p>
              <p className="price">$ {card.totalAmount}</p>
            </div>
            <div className="btn-wrap">
              {/* <div
                className="btn style01 sm"
                onClick={() => {
                  setCartItemOpen(!cartItemOpen);
                }}
              >
                Edit
              </div> */}
              <div
                className="btn style01 sm delete"
                onClick={(e) => {
                  e.stopPropagation();
                  delCartType(item.idx);
                }}
              >
                <span className="material-symbols-rounded">delete</span>
              </div>
              <span className="material-symbols-rounded arr">
                keyboard_arrow_down
              </span>
            </div>
          </div>

          {cartItemOpen && (
            <div className="bottom-area">
              <div className="game">
                <p className="prize">
                  $ {amountMaker(Number(card.jackpot))} million
                </p>
                {Array.isArray(card.playdate) ? (
                  card.playdate.map((it, idx) => (
                    <p key={idx} className="date">
                      {it}
                    </p>
                  ))
                ) : (
                  <p className="date">{card.playdate}</p>
                )}
              </div>

              <div className="ticket-wrap">
                {card.nums.map((it: Nums, idx: number) => (
                  <div className="item" key={idx}>
                    <div className="ticket-tit-wrap">
                      <p>Game #{idx + 1}</p>
                      <div className="btn-wrap">
                        <span
                          className="btn material-symbols-rounded"
                          onClick={() =>
                            delTicket(card.idx, card.nums[idx].idx)
                          }
                        >
                          delete
                        </span>
                      </div>
                    </div>
                    <div className="picked-ball-wrap">
                      <div className="ball-wrap">
                        {it.num.split(",").map((it, idx, arr: string[]) => (
                          <div
                            key={idx}
                            className={`ball ${
                              idx === arr.length - 1 ? "guaranteed" : ""
                            }`}
                          >
                            {it}
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="item multiplier">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={card.multiplier === "Y"}
                            disabled={stopMulti === true}
                            onClick={() =>
                              editCartType(card.idx, card.multiplier)
                            }
                          />
                        }
                        label="Add multiplier $3.45 per game"
                        labelPlacement="start"
                      />
                    </div>

                    {/* ---- show nums ---- */}
                    <ShowNums
                      nums={it.num}
                      code={card.productCode}
                      sysCode={sysCode}
                    />
                    {/* ---- show nums ---- */}
                  </div>
                ))}
                {/* <div className="item">
                  <div className="ticket-tit-wrap">
                    <p className="">Game #2</p>
                    <div className="btn-wrap">
                      <span className="btn material-symbols-rounded">bolt</span>
                      <span className="btn material-symbols-rounded">mop</span>
                      <img
                        className="btn"
                        src={fav ? FavIco : FavIcoChk}
                        onClick={() => {
                          setFav(!fav);
                        }}
                      />
                      <span className="btn material-symbols-rounded">
                        delete
                      </span>
                    </div>
                  </div>
                  <div className="picked-ball-wrap">
                    <div
                      className="ball-wrap"
                      onClick={() => {
                        setBallEditOpen(!ballEditOpen);
                      }}
                    >
                      <div className="ball">1</div>
                      <div className="ball">2</div>
                      <div className="ball">3</div>
                      <div className="ball">4</div>
                      <div className="ball">5</div>
                      <div className="ball guaranteed">G</div>
                      <span
                        className={`material-symbols-rounded ${
                          ballEditOpen ? "open" : ""
                        }`}
                      >
                        keyboard_arrow_down
                      </span>
                    </div>
                    {ballEditOpen && (
                      <>
                        <div className="edit-ball-wrap">
                          <div className="ball-count">
                            <div className="tit-count-wrap">
                              <p className="tit">Ball</p>
                              <p className="count">
                                <span>1</span>of 5
                              </p>
                            </div>
                          </div>


<div className="ball-wrap">
                            {[
                              1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
                              16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
                              28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
                              40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51,
                              52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63,
                              64, 65, 66, 67, 68, 69, 70,
                            ].map((a, i) => {
                              return (
                                <div
                                  className={`ball ${isPicked ? "picked" : ""}`}
                                  key={i}
                                  onClick={() => {
                                    setIsPicked(!isPicked);
                                  }}
                                >
                                  {a}
                                </div>
                              );
                            })}
                          </div>

                          <div
                            className="tit-count-wrap"
                            style={{ marginTop: "12px" }}
                          >
                            <p className="mega">Mega ball</p>
                            <p className="count">
                              <span>1</span>of 1
                            </p>
                          </div>

                          <div className="ball-wrap mega">
                            {[
                              1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
                              16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
                            ].map((a, i) => {
                              return (
                                <div
                                  className={`ball ${isPicked ? "picked" : ""}`}
                                  key={i}
                                  onClick={() => {
                                    setIsPicked(!isPicked);
                                  }}
                                >
                                  {a}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        <div className="btn style01 md">Confirm</div>
                      </>
                    )}
                  </div>
                </div> */}
              </div>
            </div>
          )}
        </div>
      );
    }
  };

  return (
    <>
      {loading ? (
        <Skeleton
          animation="pulse"
          variant="rounded"
          style={{
            width: "100%",
            height: 70,
            margin: "0 auto",
            marginTop: "10px",
            borderRadius: "14px",
          }}
        />
      ) : (
        isCardType(item)
      )}
    </>
  );
};

export default React.memo(CartItem);
