import axios from "axios";
import { reset, update } from "../../../tools/zustandUpdate";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Swal from "sweetalert2";
import User from "../../../tools/userInfo";
import { error } from "console";

// type
interface GameInfo {
  drawnum: number;
  jackpot: number;
  playdate: string;
  orderTime: string;
}

interface FavProps {
  type: string;
  setLogin: Dispatch<SetStateAction<boolean>>;
  gameInfo: GameInfo;
}

const Favorites = ({ type, gameInfo, setLogin }: FavProps) => {
  const { api, token, info, setTotalInfo } = User();
  const [test, setTest] = useState<any>();

  useEffect(() => {
    if (!token) {
      Swal.fire({
        icon: "warning",
        title: "Members Only",
        text: "This method is only for Lottery Cluster members",
        allowOutsideClick: false,
      });
      setTimeout(() => {
        window.location.href = "/";
      }, 1000);
    } else {
      axios
        .get(`https://3c60-13-209-180-206.ngrok-free.app/v1/favorite`, {
          headers: {
            "ngrok-skip-browser-warning": "69420",
            Authorization: "bearer " + token,
          },
        })
        .then((res) => setTest(res.data.data))
        .catch((error) => console.error(error));
    }
  }, []);

  const removeFav = (idx: string) => {
    axios
      .delete(`https://3c60-13-209-180-206.ngrok-free.app/v1/favorite/${idx}`, {
        headers: {
          "ngrok-skip-browser-warning": "69420",
          Authorization: "bearer " + token,
        },
      })
      .then((res) => {
        console.log(res);
        window.location.reload();
      })
      .catch((error) => console.error(error));
  };

  console.log(test);

  return (
    <div className="sub fav">
      <h1>Favorites</h1>
      {test?.map((it: any) => (
        <div key={it.idx}>
          {it.idx} - {it.code} - {it.method} - {it.option} - {it.quantity}
          <button onClick={() => removeFav(it.idx)}>del</button>
        </div>
      ))}
    </div>
  );
};

export default Favorites;
