// tools
import { useEffect, useState } from "react";
import { MenuItem, Select } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

// constants
import StatMenu from "../constants/statMenu";

// comp
import Bar from "../components/statistics/bar";
import Consecutive from "../components/statistics/consecutive";
import Pattern from "../components/statistics/pattern";
import HighLow from "../components/statistics/highlow";
import OddEven from "../components/statistics/oddeven";
import { useNavigate } from "react-router-dom";

const Stat = () => {
  const [menu, setMenu] = useState("");
  const [tab, setTab] = useState("");
  const [month, setMonth] = useState("1");
  const isFold = useMediaQuery("(max-width: 840px)");

  const navigate = useNavigate();

  useEffect(() => {
    const search = location.search.slice(1, location.search.length);

    if (search) {
      const split = search.split("&");
      const type = split[0].split("=")[1];
      const tab = split[1].split("=")[1];

      setMenu(type);
      setTab(tab);
    } else {
      setMenu("mega");
      setTab("lastdrawn");
    }
  }, []);

  const viewChange = (about: string) => {
    switch (about) {
      case "lastdrawn":
        return <Bar type={menu} menu={about} month={month} tab={tab} />;
        break;

      case "frequency":
        return <Bar type={menu} menu={about} month={month} tab={tab} />;
        break;

      case "oddeven":
        return <OddEven type={menu} month={month} />;
        break;

      case "highlow":
        return <HighLow type={menu} month={month} />;
        break;

      case "consecutive":
        return <Consecutive type={menu} month={month} />;
        break;

      case "pattern":
        return <Pattern type={menu} month={month} />;
        break;

      default:
        return;
    }
  };

  return (
    <div className="sub stat">
      <div className="sub-top-area">
        <h3 className="tit">Statistics</h3>
        <p>Analyze the numbers with the highest probability</p>
      </div>

      <div className="tab-month-wrap">
        <ul className="tab style01">
          <li
            className={menu === "mega" ? "current" : ""}
            onClick={() => (location.href = `/stat?type=mega&tab=${tab}`)}
          >
            Mega Millions
          </li>
          <li
            className={menu === "power" ? "current" : ""}
            onClick={() => (location.href = `/stat?type=power&tab=${tab}`)}
          >
            Powerball
          </li>
        </ul>
        {!isFold && tab !== "lastdrawn" && (
          <Select
            id=""
            value={month}
            onChange={({ target }) => setMonth(target.value)}
            className="month"
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200,
                },
              },
              disableScrollLock: true,
            }}
          >
            <MenuItem value={"1"}>1 Month</MenuItem>
            <MenuItem value={"2"}>2 Months</MenuItem>
            <MenuItem value={"3"}>3 Months</MenuItem>
            <MenuItem value={"4"}>4 Months</MenuItem>
            <MenuItem value={"5"}>5 Months</MenuItem>
            <MenuItem value={"12"}>1 Year</MenuItem>
            <MenuItem value={"36"}>3 Years</MenuItem>
            <MenuItem value={"60"}>5 Years</MenuItem>
          </Select>
        )}
      </div>
      <div className="stat-wrap">
        {!isFold ? (
          <ul className="tab style02">
            {StatMenu.map((item) => (
              <li
                key={item.text}
                className={tab === item.menu ? "current" : ""}
                onClick={() =>
                  (location.href = `/stat?type=${menu}&tab=${item.menu}`)
                }
              >
                {item.text}
              </li>
            ))}
          </ul>
        ) : (
          <div className="select-tab-wrap">
            <Select
              defaultValue={1}
              className="tab"
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200,
                  },
                },
                disableScrollLock: true,
              }}
            >
              {StatMenu.map((it, idx) => (
                <MenuItem
                  key={idx}
                  value={idx + 1}
                  onClick={() => {
                    navigate(`/stat?type=${menu}&tab=${it.menu}`);
                    setTab(it.menu);
                  }}
                >
                  {it.text}
                </MenuItem>
              ))}
            </Select>
            {tab !== "lastdrawn" && (
              <Select
                id=""
                value={month}
                onChange={({ target }) => setMonth(target.value)}
                className="month"
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                  disableScrollLock: true,
                }}
              >
                <MenuItem value={"1"}>1 Month</MenuItem>
                <MenuItem value={"2"}>2 Months</MenuItem>
                <MenuItem value={"3"}>3 Months</MenuItem>
                <MenuItem value={"4"}>4 Months</MenuItem>
                <MenuItem value={"5"}>5 Months</MenuItem>
                <MenuItem value={"12"}>1 Year</MenuItem>
                <MenuItem value={"36"}>3 Years</MenuItem>
                <MenuItem value={"60"}>5 Years</MenuItem>
              </Select>
            )}
          </div>
        )}
        {viewChange(tab)}
      </div>
    </div>
  );
};

export default Stat;
