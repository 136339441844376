// tools
import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import User from "../tools/userInfo";

// ui
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";

const EmailUs = () => {
  const [info, setInfo] = useState({
    name: "",
    email: "",
    topic: "",
    content: "",
  });
  const { api } = User();

  const handleData = (key: string, value: string) => {
    setInfo((prev) => ({ ...prev, [key]: value }));
  };

  const matches = useMediaQuery("(max-width:480px)");

  const emailAxios = () => {
    const values = Object.values(info);
    const keys = Object.keys(info);

    const empty = values.findIndex((x) => !Boolean(x));

    if (empty === -1) {
      axios
        .post(`${api}/support/contact/email`, info, {
          headers: {
            "ngrok-skip-browser-warning": "69420",
          },
        })
        .then((_) => {
          Swal.fire({
            title: "Success",
            icon: "success",
            text: "Email has been sent successfully. We will response as soon as possible.",
            allowOutsideClick: false,
            didClose: () => (location.href = "/"),
          });
        })
        .catch(({ response }) => {
          Swal.fire({
            title: "Error",
            text: response.data.message,
            icon: "error",
            allowOutsideClick: false,
            didClose: () => window.location.reload(),
          });
        });
    } else {
      Swal.fire({
        title: "Error",
        text: `You haven't filled out a required field: ${keys[empty]}`,
        icon: "error",
        allowOutsideClick: false,
      });
    }
  };

  return (
    <div className="sub email-us">
      <div className="bg"></div>
      <div className="email-us-wrap">
        <div className="sub-top-area">
          <h3 className="tit">Email Us</h3>
          <p>
            Our team of lottery experts is prepared to provide a quick and
            thorough response to all your questions and concerns via email.
          </p>
        </div>

        <form>
          <div className="input-wrap">
            <TextField
              label="Name"
              sx={{ width: "50%" }}
              value={info.name}
              onChange={({ target }) => handleData("name", target.value)}
            />
          </div>
          <div className="input-wrap">
            <TextField
              label="Email"
              sx={{ width: "50%" }}
              value={info.email}
              onChange={({ target }) => handleData("email", target.value)}
            />
          </div>
          <div className="input-wrap">
            <TextField
              label="Topic"
              fullWidth
              value={info.topic}
              onChange={({ target }) => handleData("topic", target.value)}
            />
          </div>
          <TextField
            label="Content"
            fullWidth
            multiline
            rows={10}
            defaultValue=""
            onChange={({ target }) => handleData("content", target.value)}
          />

          <div className="btn-wrap">
            <div className="btn style01 md white" onClick={() => emailAxios()}>
              Send
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EmailUs;
