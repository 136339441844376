import { useEffect, useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

// img

import User from "../tools/userInfo";
import axios from "axios";
import CartItem from "../components/cart/cartItem";
import Swal from "sweetalert2";
import { update } from "../tools/zustandUpdate";

// type
export interface Ball {
  white: number;
  special: number;
}

export interface BallSet {
  [key: string]: Ball;
  mega: Ball;
  power: Ball;
}

export interface Nums {
  idx: number;
  num: string;
}

export interface CartItems {
  draws: number; // 회차 갯수
  idx: number; // 게임키
  jackpot: number; // 당첨금
  methodCode: string; // 게임 종류
  multiplier: string; // 멀티 유무
  nums: Nums[]; // 게임 모음
  playdate: string[]; // 구매할 회차 날짜
  productCode: string; // 게임 종목
  productName: string; // 게임 이름
  subscription: string; // 서브스크랩 유무
  systemCode: string; // 게임 상세 종류
  ticketQty: number; // 게임 갯수
  totalAmount: number; // 게임 가격
}

// constant
const BallSet: BallSet = {
  mega: {
    white: 70,
    special: 25,
  },
  power: {
    white: 69,
    special: 26,
  },
};

const Cart = () => {
  const { api, token, setToken, setTotalInfo } = User();
  const [items, setItems] = useState<CartItems[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  // local
  const noLg = JSON.parse(localStorage.getItem("noLgCart") || "[]");

  // func
  const totalPrice = (items: CartItems[]) => {
    let total = 0;
    for (let i = 0; i < items.length; i++) {
      total += Number(items[i].totalAmount);
    }
    return total;
  };

  // delCart typeCheck
  const delCartType = (target: number | string) => {
    if (typeof target === "string") {
      deleteCart(parseInt(target, 10));
    } else {
      deleteCart(target);
    }
  };

  // update cartCnt
  const updateCart = () => {
    axios
      .get(`${api}/auth/me`, {
        headers: {
          "ngrok-skip-browser-warning": "69420",
          Authorization: "bearer " + token,
        },
      })
      .then((res) => update(api, token, setTotalInfo));
  };

  // effect
  useEffect(() => {
    if (items.length === 0) {
      setLoading(true);
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 100);
    }
  }, [items]);

  useEffect(() => {
    if (token.length < 1) {
      setItems(noLg);
    } else {
      updateCart();
      loginCart();
      setTimeout(() => {
        getCart();
      }, 100);
    }
  }, []);

  // API

  /** 로그인시 비로그인 카트 서버로 전송 */
  const loginCart = () => {
    if (token.length > 1 && Array.isArray(noLg) && noLg.length > 0) {
      const updatedNoLg = noLg.map((item) => {
        return {
          ...item,
          nums: item.nums.map((it: any) => ({
            ...it,
            num: it.num.split(",").map(Number),
          })),
        };
      });

      console.log(updatedNoLg);

      axios
        .post(`${api}/cart`, updatedNoLg, {
          headers: {
            "ngrok-skip-browser-warning": "69420",
            Authorization: "bearer " + token,
          },
        })
        .then((res) => {
          console.log(res);
          localStorage.removeItem("noLgCart");
          window.location.reload();
        })
        .catch((error) => console.error(error));
    } else {
      setItems(noLg);
    }
  };

  /** 카트 개별 삭제 */
  const deleteCart = (target: number) => {
    if (token.length === 0) {
      const copy = [...items];
      const updateCart = copy.filter((it) => it.idx != target);
      localStorage.setItem("noLgCart", JSON.stringify(updateCart));
      setItems(updateCart);
    } else {
      axios
        .delete(`${api}/cart/${target}`, {
          headers: {
            "ngrok-skip-browser-warning": "69420",
            Authorization: "bearer " + token,
          },
        })
        .then((res) => {
          setItems((prev) => prev.filter((item) => item.idx !== target));
          updateCart();
        })
        .catch((error) => console.log(error));
    }
  };

  /** 카트 전체 삭제 */
  const clearCart = () => {
    if (token.length === 0) {
      localStorage.removeItem("noLgCart");
      setItems([]);
    } else {
      axios
        .delete(`${api}/cart/clear`, {
          headers: {
            "ngrok-skip-browser-warning": "69420",
            Authorization: "bearer " + token,
          },
        })
        .then((res) => {
          Swal.fire({
            title: "Success clear your cart",
            icon: "success",
            allowOutsideClick: false,
            timer: 1000,
            didClose: () => {
              window.location.reload();
            },
          });
          localStorage.removeItem("noLgCart");
        })
        .catch((error) => console.log(error));
    }
  };

  const getCart = () => {
    axios
      .get(`${api}/cart`, {
        headers: {
          "ngrok-skip-browser-warning": "69420",
          Authorization: "bearer " + token,
        },
      })
      .then((res) => setItems(res.data.data || []))
      .catch((error) => console.log(error));
  };

  return (
    <div className="sub cart">
      <div className="sub-top-area">
        <h3 className="tit">Checkout</h3>
        <p className="price">
          Total : <span>$ {totalPrice(items).toFixed(2)}</span>
        </p>
        <div className="btn-wrap">
          <div className="btn style01 sm delete" onClick={() => clearCart()}>
            <span className="material-symbols-rounded">delete</span>
          </div>
          <div className="btn style01 sm spc">Continue</div>
        </div>
      </div>
      {/* item component wrap */}
      <div className="cart-wrap">
        {items.length === 0 ? (
          <div className="empty-cart">
            <h3>Unfortunately, your cart is empty</h3>
            <p>Please add something to your cart</p>
            <div className="btn-wrap">
              <div
                className="btn style01 md mega"
                onClick={() => (window.location.href = "/mega")}
              >
                Mega Millions play
                {/* <span className="material-symbols-rounded">chevron_right</span> */}
              </div>
              <div
                className="btn style01 md power"
                onClick={() => (window.location.href = "/power")}
              >
                Powerball play
                {/* <span className="material-symbols-rounded">chevron_right</span> */}
              </div>
            </div>
          </div>
        ) : (
          items.map((it) => (
            <CartItem
              key={it.idx}
              item={it}
              delCartType={delCartType}
              setItems={setItems}
              getCart={getCart}
              loading={loading}
            />
          ))
        )}
      </div>

      {/* bottom check */}
      <div className="cart-bottom-area">
        <h3 className="tit">Checkout</h3>
        <p className="price">
          Total : <span>$ {totalPrice(items).toFixed(2)}</span>
        </p>
        <div className="btn-wrap">
          <div className="btn style01 sm delete" onClick={() => clearCart()}>
            <span className="material-symbols-rounded">delete</span>
          </div>
          <div className="btn style01 sm spc">Continue</div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
